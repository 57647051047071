import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/shared/classes/custom.error-matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/service/firebase.service';


export interface Tag{
  type: string; 
  color: string; 
}

export interface CourseOffer {
  name: string;
  price: number;
  tags: Tag[];
  time: string;
}

const ELEMENT_DATA: CourseOffer[] = [
  { time: 'Stunde', price: 12, name: 'Autogenes Training', tags: [{ type: 'Kurs', color: '#3f51b5' }] },
  { time: 'Stunde', price: 12, name: 'Progressive Muskelrelaxation', tags: [{ type: 'Kurs', color: '#3f51b5' }] },
  { time: 'Stunde', price: 12, name: 'Beckenbodentraining', tags: [{ type: 'Kurs', color: '#3f51b5' }] },
  { time: 'halbe Stunde', price: 30, name: 'Klangschalenmassage', tags: [{ type: 'Einzelstunde', color: '#32b72f' }] },
  { time: 'halbe Stunde', price: 30, name: 'Triggerpunktbehandlungen ', tags: [{ type: 'Einzelstunde', color: '#32b72f' }] },

];

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {

  public map: any = { lat: 50.148375, lng: 8.5334922 };
  contactForm: FormGroup;
  matcher = new CustomErrorStateMatcher();
  error: Observable<string>;
  loading: boolean = false;

  groupOffers = [
    {id: '0', name: 'Autogenes Training', info: '8 Einheiten a 1 Stunde', price: '96'},
    {id: '1', name: 'Meditation', info: '8 Einheiten a 1 Stunde', price: '96'},
    {id: '2', name: 'Progressive Muskelrelaxation', info: '8 Einheiten a 1 Stunde', price: '96'},
  ]
  singleOffers = [
    {id: '3',name: 'Klangschalen-Behandlung', info: '60 Minuten', price: '60'},
    {id: '4',name: 'Myofasziale Triggerpunkt-Behandlung', info: '60 Minuten', price: '60'},
    // {id: '5',name: 'Klangschalen-Behandlung', info: '90 Minuten', price: '75'},
    // {id: '6',name: 'Klangschalen-Behandlung', info: '90 Minuten', price: '75'},
  ]
  
  displayedColumns: string[] = ['name', 'tags', 'time', 'price'];
  dataSource = ELEMENT_DATA;
  data$: any;
  
  constructor(
    public fireService: FirebaseService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.contactForm = this.fb.group({
      name: ['', [
        Validators.required
      ]],
      company: ['', [ 
        Validators.required
      ]],
      subject: ['', [
        Validators.required
      ]],
      message: ['', [
        Validators.required
      ]],
      phone: ['', [
        Validators.required
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
    })
  }


  ngOnInit(): void {
    this.fireService.loadPricesData();
    this.fireService.prices$.subscribe(data => {
      this.data$ = data;
    })
  }

  toggleGroupCardInput: any = {};
  toggleIndividualCardInput: any = {};


  async onSave() {
    this.fireService.updatePricesData(this.data$);
  }

  get emailContactForm() { return this.contactForm.get('email') }
  get passwordContactForm() { return this.contactForm.get('password') }

  onSubmitContactForm(formDirective: FormGroupDirective): void {
    if (this.contactForm.valid) {
    };
    formDirective.resetForm();
    this.contactForm.reset();
  }

  openSnackBar() {
    this._snackBar.openFromComponent(LoginSnackBarComponent, {
      duration: 10000,
      panelClass: ['bg-green-500']
    });
  }
}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      Erfolgreich eingelogged!
    </div>
  `
})
export class LoginSnackBarComponent { }

import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/service/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDialogComponent } from 'src/app/components/dialogs/appointment-dialog/appointment-dialog.component';


@Component({
  selector: 'app-entspannung',
  templateUrl: './entspannung.component.html',
  styleUrls: ['./entspannung.component.scss']
})
export class EntspannungComponent implements OnInit {

  offers = [
    {
      name: 'Klangschalen-Behandlung',
      title: '',
      description: 'Selbstwahrnehmung erfahren - Stresssymptomen entgegenwirken. Durch die sanften Schwingungen einer Klangschale verwöhnen Sie bei einer Behandlung Körper, Geist und Seele. Traditionelle Klangschalen werden gezielt auf den bekleideten Körper positioniert und ins Schwingen und Tönen versetzt. Hören und Fühlen stehen hier im Einklang. Die feinen Vibrationen breiten sich behutsam im Körper aus und beruhigen Körper und Seele, harmonisieren, regenerieren und vitalisieren Lebensfreude und Schaffenskraft.',
      src: '../../../assets/images/women.jpg',
      watch: '../../../assets/images/watch.png',
      images: [
        {
          src: '../../../assets/images/k1.jpg',
        },
        {
          src: '../../../assets/images/k2.jpg',
        },
        {
          src: '../../../assets/images/k3.jpg',
        },
      ],
      condition: [
        {
          time: '60 Min',
          price: '60 €',
        },
        {
          time: '90 Min',
          price: '75 €',
        }
      ]
    },
    {
      name: 'Autogenes-Training',
      title: '',
      description: 'Autogenes Training ist ein vom Berliner Arzt Johannes Heinrich Schulz 1932 aus der Hypnose entwickeltes Entspannungsverfahren. Es wird weltweit praktiziert und beruht auf Autosuggestion. In meinen Kursen erlernen sie diese Methode, um Sie selbständig anwenden zu können.',
      src: '../../../assets/images/auto.jpg',
      watch: '../../../assets/images/watch.png',
      images: [

      ],
      condition: [
        {
          time: '8 Einheiten a 1 Stunde',
          price: '96 €',
        }
      ]
    },
    {
      name: 'Meditation',
      title: '',
      description: 'Meditationstechniken werden als Hilfsmittel verstanden den Geist zu beruhigen und zu sammeln.  Viele Meditationstechniken sollen helfen, einen Bewusstseinszustand zu erreichen, in dem äußerst klares hellwaches Gewahrsein und tiefste Entspannung gleichzeitig möglich sind. Die Anfängergruppen werden in geführten Meditationen schrittweise mit dieser Entspannungstechnik vertraut gemacht. Fortgeschrittene können zunehmend selbständig, allein oder weiter in Gruppen meditieren.',
      src: '../../../assets/images/meditation.jpg',
      watch: '../../../assets/images/watch.png',
      images: [

      ],
      condition: [
        {
          time: '8 Einheiten a 1 Stunde',
          price: '96 €',
        }
      ]
    },
    {
      name: 'Progressive Muskelrelaxation',
      title: '',
      description: 'Die Methode der Progressiven Muskelrelaxation wurde in den zwanziger Jahren vom amerikanischen Arzt Edmund Jacobsen entwickelt. Der Ansatzpunkt dieses Entspannungsverfahrens beruht auf der Wechselwirkung zwischen muskulärer und psychischer Anspannung. Anders ausgedrückt: die Lockerung der Muskulatur, die im Entspannungstraining hervorgerufen wird, führt im seelischen Erleben zu einem Ruhegefühl. Dieses vertiefte Ruhegefühl bewirkt wiederum eine zunehmende Muskelentspannung – wodurch ein positiver Kreislauf entsteht. Wer längere Zeit regelmäßig PMR praktiziert, entwickelt zunehmend Ruhe, Gelassenheit und gewinnt an Konzentration.',
      src: '../../../assets/images/floor.jpg',
      watch: '../../../assets/images/watch.png',
      images: [

      ],
      condition: [
        {
          time: '8 Einheiten a 1 Stunde',
          price: '96 €',
        }
      ]
    },
    {
      name: 'Myofasziale Triggerpunkt-Behandlung',
      title: '',
      description: 'Bei myofaszialen Triggerpunkten handelt es sich um punktuelle Verhärtungen in den Muskeln und in den dazugehörigen Muskelfaszien. Sie gelten als die häufigste Ursache von Schmerzen und Funktionsstörungen im Bewegungsapparat. Durch gezieltes Aufspüren und Auslösen kann sich langfristig eine Verbesserung der Beschwerden einstellen.',
      src: '../../../assets/images/shoulder.jpg',
      watch: '../../../assets/images/watch.png',
      images: [

      ],
      condition: [
        {
          time: '60 Min',
          price: '60 €',
        },
        {
          time: '90 Min',
          price: '75 €',
        }
      ]
    },
  ]

  data$: any;

  constructor(
    public dialog: MatDialog,
    public fireService: FirebaseService,
  ) { }

  ngOnInit(): void {
    this.fireService.loadPricesData();
    this.fireService.prices$.subscribe(data => {
      this.data$ = data;
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AppointmentDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
    });
  }

  toggleOfferCardInput: any = {};


  async onSave() {
    this.fireService.updatePricesData(this.data$);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from 'src/app/service/firebase.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  loading: boolean = false;
  success: boolean;
  infoMessage: string;
  successMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private fireService: FirebaseService
    ) {
    this.activatedRoute.paramMap.subscribe(async params => {
      try {
        this.loading = true;
        let token = params.get('token');
        let res = await this.fireService.postNewsletterConfirmation(token);
        if (res.success) { this.successMessage = 'Sie erhalten ab sofort den Newsletter'; this.success = true; }
        if (res.info) this.infoMessage = 'Sie sind bereits im Newsletter eingetragen'
      } catch (error) {
        this.success = false;
      }
      this.loading = false;
    });
  }

  ngOnInit(): void {

  }

}

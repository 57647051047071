<div class="bg-white" style="background-color: #F6F6F6;">
    <div class="max-w-md mx-auto py-16 relative z-30">


        <mat-card style="padding-top: 40px; padding-bottom: 40px;" class="overflow-hidden">
            <div class="absolute top-0 inset-x-0 w-full">
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>
            <form [formGroup]="loginForm" #loginFormDirective="ngForm"
                (ngSubmit)="onSubmitLoginForm(loginFormDirective)" class="w-full block">
                <mat-card-header>
                    <mat-card-title class="font-poppins font-semibold text-gray-800 pb-3">Login</mat-card-title>
                </mat-card-header>
                <mat-card-content class="w-full block px-3">
                    <div>
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Email eingeben</mat-label>
                            <input #email formControlName="email" matInput type="email" autocomplete="off"
                                [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Enter Password</mat-label>
                            <input #password formControlName="password" type="password" autocomplete="off"
                                matInput [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <div class="px-5 flex justify-between">
                        <a routerLink="/home">
                            <button type="button" mat-button>Zurück</button>
                        </a>
                        <!-- <button mat-raised-button class="bg-gray-200 text-gray-600 text-base font-poppins">Back</button> -->
                        <button mat-raised-button type="submit" [disabled]="!loginForm.valid"
                            class="bg-green-500 text-white text-base font-poppins">Login</button>
                    </div>
                </mat-card-actions>
            </form>
            <div *ngIf="error" class="px-3 text-red-400">
                {{error}}
            </div>
        </mat-card>
    </div>
</div>
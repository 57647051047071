import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/service/firebase.service';

@Component({
  selector: 'app-request-unsubscription',
  templateUrl: './request-unsubscription.component.html',
  styleUrls: ['./request-unsubscription.component.scss']
})
export class RequestUnsubscriptionComponent implements OnInit {

  msg: string;
  loading: boolean = false;

  constructor(
    private fireService: FirebaseService
  ) {}


  async unsubscribe() {
    this.loading = true;
    await this.fireService.requestEmailListDeletion(this.msg)
    this.loading = false;
  }

  ngOnInit(): void {
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { AccountComponent } from './views/account/account.component';

// import { OuterGuard } from './shared/guards/outer.guard';
// import { InnerGuard } from './shared/guards/inner.guard';
import { AboutComponent } from './views/about/about.component';
import { PricesComponent } from './views/prices/prices.component';
import { ImpressComponent } from './views/impress/impress.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { ErnaehrungComponent } from './views/ernaehrung/ernaehrung.component';
import { BesuchshundComponent } from './views/besuchshund/besuchshund.component';
import { ContactComponent } from './views/contact/contact.component';
import { EntspannungComponent } from './views/entspannung/entspannung.component';
import { RehabsportsComponent } from './views/rehabsports/rehabsports.component';
import { BetrieblichegesundheitComponent } from './views/betrieblichegesundheit/betrieblichegesundheit.component';
import { FerienhausComponent } from './views/ferienhaus/ferienhaus.component';
import { ConfirmComponent } from './views/confirm/confirm.component';
import { UnsubscribeComponent } from './views/unsubscribe/unsubscribe.component';
import { RequestUnsubscriptionComponent } from './views/request-unsubscription/request-unsubscription.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'ringana/shop.html', component: HomeComponent },
  { path: 'confirm/:token', component: ConfirmComponent },
  { path: 'unsubscribe/:token', component: UnsubscribeComponent },
  { path: 'preise', component: PricesComponent },
  { path: 'betrieblichegesundheit', component: BetrieblichegesundheitComponent },
  { path: 'ferienhaus', component: FerienhausComponent },
  { path: 'bewegung', component: RehabsportsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'entspannung', component: EntspannungComponent },
  { path: 'about', component: AboutComponent },
  { path: 'ueber-mich.html', component: AboutComponent },
  { path: 'ernaehrung', component: ErnaehrungComponent },
  { path: 'besuchshund', component: BesuchshundComponent },
  { path: 'impress', component: ImpressComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'requestUnsubscription', component: RequestUnsubscriptionComponent },
  { path: 'login', component: LoginComponent },
  { path: 'account', component: AccountComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

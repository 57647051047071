<h1 mat-dialog-title class="text-center">Rezension schreiben</h1>
<div mat-dialog-content>
  <div class="absolute top-0 inset-x-0 w-full mr-5">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="bg-white mr-5 p-3 rounded-sm border border-gray-500 m-2 mt-0 overflow-hidden w-full"
    style="min-width: 250px; max-width: 250px;">
    <div class="flex justify-center">

      <input class="object-cover w-full h-full " accept="image/*" #file_input_one type="file" style="display:none"
        (change)="getSrcOne($event)">
      <button
        class="h-24 w-24 mx-auto rounded-full overflow-hidden bg-gray-100 items-center justify-center flex shadow-one hover:bg-gray-300 trans cursor-pointer"
        style="padding:0px; margin:0px;" (click)="file_input_one.click()">
        <mat-icon *ngIf="!src_one; else image_ref_one">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor"
              d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />
          </svg>
        </mat-icon>
        <ng-template #image_ref_one>
          <img class="object-cover rounded-md overflow-hidden h-48 w-full h-full" [src]="src_one" alt="" />
        </ng-template>
      </button>
    </div>

    <div class="p-px bg-green-500 mx-5 rounded-full mt-2"></div>
    <div class="text-gray-700 text-center font-lato mt-5 px-5">
      <div class="font-medium text-lg text-center">
        <input [(ngModel)]="header" class="text-center" type="text" placeholder="Begrüßung"
          class="border border-gray-300 w-full m-0">
      </div>
      <div class="mt-3">
        <textarea type="text" placeholder="Hier wird der kurze Hauptteil eingefügt ..." [(ngModel)]="main"
          class="h-48 border border-gray-300 w-full m-0"></textarea> </div>
      <div class="mt-4">
        <textarea [(ngModel)]="footer" class="border border-gray-300 w-full m-0" name="" id=""
          placeholder="Schlusswort"></textarea>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center">
    <div class="mt-4 text-center font-semibold font-poppins text-lg">Code</div>
    <input [(ngModel)]="code" type="number" class="bg-gray-200 border border-gray-500 mx-auto mt-2 py-2 px-3">
  </div>
</div>
<div mat-dialog-actions>
  <div class="pb-3 pt-2 w-full">
    <button (click)="uploadReview()" mat-raised-button
      class="text-white bg-green-500 mt-1 pt-0 w-full">Absenden</button>
  </div>
</div>
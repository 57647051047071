<section class="relative">
    <div class="fixed top-0 mt-16 right-0 z-40 pt-5 pr-8">
        <button color="primary" mat-raised-button *ngIf="fireService.user$ | async as user"
            (click)="onSave()">Speichern</button>
    </div>
    <div class="relative z-20 font-poppins text-2xl font-semibold pt-16 text-center">Entspannungsangebot<span
            class="text-green-500">.</span>
    </div>
    <section class="lg:w-full sm:mx-20 lg:max-w-6xl lg:mx-auto mt-6">
        <mat-tab-group>
            <mat-tab *ngFor="let offer of offers; let i = index" [label]="offer.name">
                <ng-template matTabContent>
                    <div class="pb-12">
                        <section class="relative">
                            <figure class="w-full">
                                <img class="w-full h-64 sm:h-72 object-cover shadow-two rounded-sm overflow-hidden" [src]="offer.src" alt="">
                                <div class="absolute inset-x-0 mt-auto bottom-0 w-full text-center pb-6 font-nunito font-bold text-xl sm:text-2xl md:text-3xl">
                                    <div class="mb-2 text-white">{{offer.name}}</div>
                                    <div class="flex justify-center mx-5 sm:mx-0">
                                        <svg width="565" height="34" viewBox="0 0 565 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="white"
                                                d="M0.992188 15.5L236.992 14.5M328.992 15.5L564.992 14.5M297.999 17C297.999 25.2843 291.283 32 282.999 32C274.714 32 267.999 25.2843 267.999 17C267.999 8.71573 274.714 2 282.999 2C291.283 2 297.999 8.71573 297.999 17Z"
                                                stroke="white" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </figure>
                        </section>
                        <section class="mt-12">
                            <div class="md:grid md:grid-cols-6 flex flex-col-reverse">
                                <div class="col-span-2 flex items-center flex-col p-5 w-84 mx-auto md:mx-0 md:w-full">
                                    <figure>
                                        <img class="object-cover w-20 h-20" [src]="offer.watch" alt="">
                                    </figure>
                                    <div class="flex justify-between w-full mt-10 relative">
                                        <button mat-mini-fab [color]="toggleOfferCardInput[i] ? 'accent': 'primary'"
                                            *ngIf="fireService.user$ | async as user" (click)="toggleOfferCardInput[i] = !toggleOfferCardInput[i]">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <div *ngFor="let condition of data$?.entspannung[i].condition; let j = index" class="flex-grow-10 text-center mx-10">
                                            <ng-container *ngIf="toggleOfferCardInput[i]; else elseTemplateOne">
                                                <input type="text" name="" [(ngModel)]="data$?.entspannung[i].condition[j].time"
                                                    class="border flex items-center w-24 border-pink-600 relative  text-green-500 font-nunito font-semibold text-lg tracking-wider z-40">
                                                <div [ngClass]="{'mx-10': offer.condition.length <= 1}"
                                                    class="bg-green-500 p-px rounded-md overflow-hidden "></div>
                                                <input type="text" name="" [(ngModel)]="data$?.entspannung[i].condition[j].price"
                                                    class="border flex items-center w-24 border-pink-600 relative  text-green-500 font-nunito font-semibold text-lg z-40">
                                            </ng-container>
                                            <ng-template #elseTemplateOne>
                                                <div class="mb-2 text-green-500 font-nunito font-semibold text-lg tracking-wider">
                                                    {{condition.time}}
                                                </div>
                                                <div [ngClass]="{'mx-10': offer.condition.length <= 1}"
                                                    class="bg-green-500 p-px rounded-md overflow-hidden "></div>
                                                <div class="mt-2 text-green-500 font-nunito font-semibold text-lg">
                                                    {{condition.price}}
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                        
                                
                                <div class="p-5 col-span-4 font-nunito text-lg tracking-wide leading-7 text-gray-700">
                                    <!-- <button mat-mini-fab [color]="toggleOfferCardInput[i] ? 'accent': 'primary'"
                                        *ngIf="fireService.user$ | async as user" (click)="toggleOfferCardInput[i] = !toggleOfferCardInput[i]">
                                        <mat-icon>edit</mat-icon>
                                    </button> -->
                                    <ng-container *ngIf="toggleOfferCardInput[i]; else elseTemplateOne">
                                        <textarea  class="border flex items-center border-pink-600  w-full" [id]="i" [name]="i" rows="9"  [(ngModel)]="data$?.entspannung[i].description" ></textarea>
                                    </ng-container>
                                    <ng-template #elseTemplateOne>
                                        <div style="white-space: pre-wrap" >
                                            {{data$?.entspannung[i].description}}</div>
                                    </ng-template>
                                    
                                </div>
                            </div>
                        </section>
                        <section class="my-16 flex justify-center">
                            <!-- style="border-color: #32b72f; padding:6px; border-radius: 2px;" [href]="'mailto:gesundinbalance@gmx.de?subject=Terminanfrage%20'+offer.name+'&body=Hallo Claudia,%0A%0A'"> -->
                            <a mat-stroked-button class="bg-white w-64 hover:shadow-three text-gray-700 text-xl border-2 text-center"
                            style="border-color: #32b72f; padding:6px; border-radius: 2px;" href="https://calendar.app.google/6riBcYwqvtGyvKBs8"> 
                                <span class="mr-2">Termin anfragen</span>
                                <mat-icon>
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="#32B72F" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                                    </svg>
                                </mat-icon>
                            </a>
                        </section>
                        <section *ngIf="offer.images.length > 1" class="sm:flex mt-12">
                            <div *ngFor="let image of offer.images" class="h-64 flex-grow-10 w-full sm:p-5 p-2">
                                <figure class="w-full h-full">
                                    <img class="w-full h-full rounded-md overflow-hidden shadow-one object-cover" [src]="image.src" alt="">
                                </figure>
                            </div>
                        </section>
                    </div>
                </ng-template>
                <!-- <ng-template mat-tab-label>{{offer.name}}</ng-template> -->
            </mat-tab>
        </mat-tab-group>
    </section>
</section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rehabsports',
  templateUrl: './rehabsports.component.html',
  styleUrls: ['./rehabsports.component.scss']
})
export class RehabsportsComponent implements OnInit {

  constructor() { }

  offers = [
    {
      name: 'Orthopädiegruppe Reha-Sport',
      info: '90 min | Gruppengröße max 15 Personen',
      url: '../../../assets/images/reha2.png',
      description: 'Es ist meine Aufgabe, sie an die positiven Effekte und die Freude an der Bewegung nachhaltig heranzuführen, sodass sie nach den 90 Reha-Einheiten eigenmotiviert allein oder einer Gruppe im Sportverein weiter machen.',
      places: [
        {
          location: 'Turngemeinde Schwalbach 1887 e.V.',
          address: 'Jahnstraße 8, 65824 Schwalbach am Taunus',
          times: [
            // {
            //   day: 'Montag',
            //   hours: [{h:'16:30'}]
            // },
            {
              day: 'Dienstag',
              hours: [{ h: '15:00' }]
            },
            {
              day: 'Donnerstag',
              hours: [{h: '9:30'} ,{ h: '11:45' }]
            },
            {
              day: 'Samstag',
              hours: [{ h: '9:00' }]
            }
          ]
        },
        // {
        //   location: 'Heinrich-von-Kleist-Schule',
        //   address: 'Dörnweg 53, 65760 Eschborn',
        //   times: [
        //     {
        //       day: 'Freitag',
        //       hours: [{ h: '18:30' }]
        //     }
        //   ]
        // }
      ]
    },
    {
      name: 'Koronargruppe Herzsport',
      info: '90 min | Gruppengröße max 20 Personen',
      url: '../../../assets/images/reha1.png',
      description: 'In dieser Koronarsport-Gruppe wird Bewegungstherapie für herzkranke Menschen nach Herzinfarkt, Herzklappen- und  Bypass-Operationen durchgeführt. Dieser zertifizierte Rehabilitationssport wird unter bestimmten Voraussetzungen von der Gesetzlichen Krankenversicherung bezuschusst.',
      places: [
        {
          location: 'Turngemeinde Schwalbach 1887 e.V.',
          address: 'Jahnstraße 8, 65824 Schwalbach am Taunus',
          times: [
            {
              day: 'Dienstag',
              hours: [{h:'14:00'}]
            },
          ]
        },
        // {
        //   location: 'Heinrich-von-Kleist-Schule',
        //   address: 'Dörnweg 53, 65760 Eschborn',
        //   times: [
        //     {
        //       day: 'Donnerstag',
        //       hours: [{ h: '18:30' }]
        //     }
        //   ]
        // }
      ]
    },
    {
      name: 'Onkologie',
      info: '90 min | Gruppengröße max 20 Personen',
      url: '../../../assets/images/Onkologie.jpeg',
      description: '',
      places: [
        {
          location: 'Turngemeinde Schwalbach 1887 e.V.',
          address: 'Jahnstraße 8, 65824 Schwalbach am Taunus',
          times: [
            // {
            //   day: 'Dienstag',
            //   hours: [{h:'14:00'}]
            // },
            {
              day: 'Samstag',
              hours: [{h:'10:00'}]
            }
          ]
        }
      ]
    },
    // {
    //   name: 'Wassergymnastik',
    //   info: '90 min | Gruppengröße max 20 Personen',
    //   url: '../../../assets/images/pool.jpg',
    //   description: 'Ob Aqua-Fitness, Aqua-Jogging oder Aqua-Cycling, jede der Varianten hat ihren Ursprung außerhalb des Schwimmbeckens. Der Clou dieser Umwandlung: Die Ausübung im Wasser verändert die Sportarten so, dass sie auch von Personen ausgeübt werden können, die ihre Sportaktivitäten im Trockenen aufgrund von Verletzungen oder Beschwerden einstellen mussten. Kraft, Ausdauer und Beweglichkeit werden schonend erneuert. Dadurch wird der Wiedereinstieg in den Sport vereinfacht',
    //   places: [
    //     {
    //       location: 'Wiesenbad Eschborn',
    //       address: 'Hauptstraße 258-260, 65760 Eschborn',
    //       times: [
    //         {
    //           day: 'Freitag',
    //           hours: [{ h: '20:00 Uhr' }]
    //         }
    //       ]
    //     }
    //   ]
    // }
  ]

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FunctionService } from './function.service';
import { first } from 'rxjs/operators';
import * as firebase from 'firebase/app';

export interface User {
  uid: string,
  displayName: string,
  email: string,
  photoURL?: string,
  emailVerified?: string,
  customData?: string,
}

export interface Messaging {
  id: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  user$: Observable<User>;
  // msg;

  constructor(
    private router: Router,
    private functions: FunctionService,
  ) {
    // this.auth.onAuthStateChanged(user => {
    //   if (user) {
    //     let idToken = user.getIdTokenResult()
    //     // console.log(idToken);
    //     this.user$ = of({
    //       uid: user.uid,
    //       displayName: user.displayName,
    //       email: user.email,
    //     })
    //   } else this.user$ = null;
    // })
    // this.auth.authState.subscribe(async user => {
    //   if (user) {
    //     this.user$ = of({
    //       uid: user.uid,
    //       displayName: user.displayName,
    //       email: user.email,
    //     })
    //   } else this.user$ = null;
    // })
  }

  isLoggedIn() {
    // return this.auth.authState.pipe(first()).toPromise();
  }

  async signIn(email: string, password: string) {
    try {
      let result = await firebase.auth().signInWithEmailAndPassword(email, password);
      if (result.user) {
        let idTokenResult = await result.user.getIdTokenResult()
        if (!!idTokenResult.claims.moderator) {
          // console.log('ismoderator')
          try {
          } catch (error) {
            // console.log(error)
          }
        }
      }
      return { success: true }
    } catch (error) {
      return { success: false }
    }
  }

  signUp(displayName: string, email: string, password: string) {
    return this.functions.register(displayName, email, password)
  }

  async signOut() {
    // this.auth.signOut().then(
    //   () => {
    //     this.user$ = of(null);
    //     this.router.navigate(['/home']);
    //   })
    //   .catch(err => {
    //     // // console.log(err)
    //   })
  }

  // private updateUserData({ uid, email, displayName, photoURL, emailVerified }: User) {
  //   // const userRef: AngularFirestoreDocument<User> = this.firestore.doc(`users/${uid}`);
  //   const data = { uid, email, displayName, photoURL, emailVerified }
  //   // return userRef.set(data, { merge: true })
  // }
}

import { Component, OnInit, Inject } from '@angular/core';
import { CustomErrorStateMatcher } from 'src/app/shared/classes/custom.error-matcher';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/storage'

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { FirebaseService } from 'src/app/service/firebase.service';
import { CsvDataService } from 'src/app/shared/services/csv-data.service';
export interface Newsletter {
  email: string;
  confirmed: boolean;
  timestamp: string;
  position: number;
}

export interface Newsletter {
  email: string;
  confirmed: boolean;
  timestamp: string;
  position: number;
}

export interface ReviewIF {
  header: string;
  footer: string;
  main: string;
  public: boolean;
  url: string;
}

let ELEMENT_DATA: Newsletter[] = [];

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(
    public fireService: FirebaseService,
    private csvService: CsvDataService,
    private _snackBar: MatSnackBar, private fb: FormBuilder) {
    this.codeForm = this.fb.group({
      code: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      for: ['', [
        Validators.required,
      ]]
    })
  }
  subscribers: Observable<Newsletter[]>;
  displayedColumns: string[];
  dataSource: MatTableDataSource<Newsletter>;
  selection: SelectionModel<Newsletter>




  public Editor1 = ClassicEditor;
  public Editor2 = ClassicEditor;
  public Editor3 = ClassicEditor;
  public Editor4 = ClassicEditor;
  public model = {
    editorData1: '',
    editorData2: '',
    editorData3: '',
    editorData4: '',
  };

  timestamp;

  matcher = new CustomErrorStateMatcher();
  layout: number;
  src_one: any;
  src_two: any;
  src_three: any;
  imageOne: any = null;
  imageTwo: any = null;
  imageThree: any = null;
  newsletterList: string[] = [];
  subject;
  codeForm: FormGroup;
  urls: string[] = [];



  layouts = [
    { number: 1, src: '../assets/images/email-layout1.jpg', alt: 'Layout-1' },
    // { number: 2, src: '../assets/images/email-layout2.jpg', alt: 'Layout-2' },
    // { number: 3, src: '../assets/images/email-layout3.jpg', alt: 'Layout-3' },
  ]



  get code() { return this.codeForm.get('code') }
  get for() { return this.codeForm.get('for') }

  onSubmitCodeForm(fb: FormGroupDirective) {
    if (this.codeForm.valid) {
      this.fireService.addCode(`${this.code.value}`, this.for.value);
      fb.reset();
      this.codeForm.reset();
    }
  }

  ngOnInit(): void {
    this.fireService.getSubscribers()
    this.fireService.loadAllReviews();
    this.fireService.getCodes();
    this.fireService.subscribers$.subscribe(subscribers => {
      ELEMENT_DATA = subscribers;
      this.displayedColumns = ['select', 'timestamp', 'email', 'confirmed'];
      this.dataSource = new MatTableDataSource<Newsletter>(ELEMENT_DATA);
      this.selection = new SelectionModel<Newsletter>(true, []);
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.newsletterList = [];
    }
    else {
      this.dataSource.data.forEach(row => this.selection.select(row));
      this.selection.selected.forEach(newsletter => {
        this.newsletterList.push(newsletter.email)
      })
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Newsletter): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.email}`;
  }

  async changeVisibility(id: string, show: boolean) {
    this.fireService.updateReview(id, !show);
  }


  async uploadImages() {
    if (!this.timestamp) {
      this.timestamp = new Date();
    }
    try {
      if (this.imageOne) {
        let snapshot = await firebase.storage().ref().child('email').child(`${Date.now()}1`).put(this.imageOne)
        let url = await snapshot.ref.getDownloadURL();
        this.urls.push(url);
      }
      if (this.imageTwo) {
        let snapshot = await firebase.storage().ref().child('email').child(`${Date.now()}2`).put(this.imageTwo)
        let url = await snapshot.ref.getDownloadURL();
        this.urls.push(url);
      }
      if (this.imageThree) {
        let snapshot = await firebase.storage().ref().child('email').child(`${Date.now()}3`).put(this.imageThree)
        let url = await snapshot.ref.getDownloadURL();
        this.urls.push(url);
      }
      return;
    } catch (error) {
      return;
    }
  }

  async sendMail(test: boolean) {
    await this.fireService.postEmail(
      this.layout, this.newsletterList, this.subject, this.model.editorData1, this.model.editorData2, this.model.editorData3, this.urls, this.model.editorData4, test
    )
    return;
  }

  async onSubmitEmailForm() {
    if (this.newsletterList.length !== 0 && this.subject) {
      try {
        if (this.urls.length === 0) {
          await this.uploadImages();
        }
        await this.sendMail(false);
        this.selection.clear();
      } catch (error) {
        // console.log('Error', error)
      }
    }
    this.clearFields();
  }

  clearFields() {
    this.imageOne = null;
    this.imageTwo = null;
    this.imageThree = null;
    this.newsletterList = [];
    this.subject = [];
    this.model.editorData1 = '';
    this.model.editorData2 = '';
    this.model.editorData3 = '';
    this.model.editorData4 = '';
    this.src_one = null;
    this.src_two = null;
    this.src_three = null;
    this.layout = 0;
  }

  addToNewsletter(list: Newsletter) {
    this.newsletterList.push(list.email)
  }

  removeFromNewsletter(list: Newsletter) {
    this.newsletterList = this.newsletterList.filter(newsletter => newsletter !== list.email)
  }

  async sendTestEmail() {
    if (this.urls.length === 0) {
      await this.uploadImages();
    }
    this.sendMail(true)
  }

  getSrcOne(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.imageOne = fileInput.target.files[0];
      let reader = new FileReader();
      reader.onload = ((e) => {
        this.src_one = e.target.result;
      });
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  getSrcTwo(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.imageTwo = fileInput.target.files[0];
      let reader = new FileReader();
      reader.onload = ((e) => {
        this.src_two = e.target.result;
      });
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  generateCSVFile() {
    // console.log('Called')
    // console.log('Generate Methond gets triggered');
    this.fireService.subscribers$.subscribe(emails => {
      // console.log(emails);
      this.csvService.exportToCsv('email_list.csv', emails);
    })
  }

  getSrcThree(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.imageThree = fileInput.target.files[0];
      let reader = new FileReader();
      reader.onload = ((e) => {
        this.src_three = e.target.result;
      });
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  openSnackBar(title: string, text: string, color: string) {
    this._snackBar.openFromComponent(EmailSnackBarComponent, {
      duration: 7000,
      data: { title, text },
      panelClass: [color],
      verticalPosition: 'bottom'
    });
  }
}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      {{data.title}} <br/>
      <div class="mt-2 font-lato font-normal text-base text-grey-800">{{data.text}}</div>
    </div>
  `
})

export class EmailSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}


<mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="over" position="end" class="w-72">
        <mat-toolbar class="mat-elevation-z6 bg-green-500 text-white">
            <span>Menu</span>
            <div class="w-full"></div>
            <button mat-icon-button color="accent" class="text-white" aria-label="Example icon button with a menu icon"
                (click)="sidenav.toggle()">
                <mat-icon class="text-white">clear</mat-icon>
            </button>
        </mat-toolbar>
        <mat-nav-list>
            <mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" [routerLink]="['/home']">Start
            </mat-list-item>
            <mat-list-item routerLinkActive="active" (click)="sidenav.toggle()" [routerLink]="['/about']">Über Mich
            </mat-list-item>
            <mat-expansion-panel [class.mat-elevation-z0]="true" dense>
                <mat-expansion-panel-header>
                    Angebote
                </mat-expansion-panel-header>
                <mat-nav-list dense>
                    <a (click)="sidenav.toggle()" routerLinkActive="active" mat-list-item
                        routerLink="/entspannung">Entspannung</a>
                    <a (click)="sidenav.toggle()" routerLinkActive="active" mat-list-item
                        routerLink="/besuchshund">Gesund mit Hund</a>
                    <a (click)="sidenav.toggle()" routerLinkActive="active" mat-list-item
                        routerLink="/bewegung">Bewegung</a>
                    <a (click)="sidenav.toggle()" routerLinkActive="active" mat-list-item
                        routerLink="/betrieblichegesundheit">Betriebliche Gesundheit</a>
                    <a (click)="sidenav.toggle()" routerLinkActive="active" mat-list-item
                        routerLink="/ernaehrung">Ernährung</a>
                    <a (click)="sidenav.toggle()" routerLinkActive="active" mat-list-item
                        routerLink="/ferienhaus">Ferienhaus</a>
                </mat-nav-list>
            </mat-expansion-panel>
            <mat-list-item (click)="sidenav.toggle()" routerLinkActive="active" [routerLink]="['/preise']">Preise
            </mat-list-item>
            <mat-list-item (click)="sidenav.toggle()" routerLinkActive="active" [routerLink]="['/contact']">Kontakt
            </mat-list-item>
            <mat-list-item *ngIf="fireService.user$ | async as user" (click)="sidenav.toggle()" routerLinkActive="active" [routerLink]="['/account']">Konto
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="overflow-y-scroll">
        <mat-toolbar class="justify-between fixed top-0 inset-y-0 w-full z-50"
            style="background-color: #F6F6F6; padding: 0 5px">
            <a routerLink="/home" class="flex items-center font-semibold font-poppins text-base md:pl-5">
                <figure class="w-24 xs:w-32 sm:w-40 mt-2 -ml-5 h-auto rounded-full overflow-hidden absolute top-0 z-40">
                    <img class="object-contain h-full w-full object-top" alt="Claudia Fink" src="../assets/images/logo-icon.webp" />
                </figure>
                <div class=" pt-1 ml-24 xs:ml-32 flex flex-col justify-center pl-2">
                    <span class="text-gray-900  text-base md:text-xl -mb-2 md:-mb-1 tracking-wider">Claudia Fink</span>
                    <span class="text-green-600 text-xs -mt-1" style="letter-spacing: 0px;">Gesund in Balance</span>
                </div>
            </a>
            <nav
                class="flex items-center tracking-wide text-gray-600 text-lg font-poppins font-semibold md:px-5 lg:px-8 xl:px-10">
                <a class="hidden md:block outline-none mx-2 px-4 py-3" routerLink="/home"
                    routerLinkActive="active">Start</a>
                <a class="hidden md:block outline-none mx-2 px-4 py-3" routerLink="/about"
                    routerLinkActive="active">Über mich</a>
                <a class="hidden md:block outline-none  py-3" routerLinkActive="active">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <div class="flex items-center">
                            <div class="tracking-wide text-gray-600 text-lg font-poppins font-bold">Angebote<span
                                    style="color: #32b72f;">!</span> </div>
                            <div>
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
                                </svg>
                            </div>
                        </div>
                    </button>
                    <mat-menu #menu="matMenu" style="width:100%" class="w-full">
                        <button style="width:100%" routerLinkActive="active" mat-menu-item
                            [routerLink]="['/entspannung']">Entspannung</button>
                        <button style="width:100%" routerLinkActive="active" mat-menu-item
                            [routerLink]="['/besuchshund']">Gesund mit Hund</button>
                        <button style="width:100%" routerLinkActive="active" mat-menu-item
                            [routerLink]="['/bewegung']">Bewegung</button>
                        <button style="width:100%" routerLinkActive="active" mat-menu-item
                            [routerLink]="['/betrieblichegesundheit']">Betriebliche
                            Gesundheit</button>
                        <button style="width:100%" routerLinkActive="active" mat-menu-item
                            [routerLink]="['/ernaehrung']">Ernährung</button>
                        <button style="width:100%" routerLinkActive="active" mat-menu-item
                            [routerLink]="['/ferienhaus']">Ferienhaus</button>
                    </mat-menu>

                </a>
                <a class="hidden md:block outline-none mx-2 px-4 py-3" routerLink="/preise"
                    routerLinkActive="active">Preise</a>
                <a class="hidden md:block outline-none mx-2 px-4 py-3" routerLink="/contact"
                    routerLinkActive="active">Kontakt</a>
                <div class="hidden md:block" *ngIf="fireService.user$ | async as user">
                    <a class="outline-none mx-2 px-4 py-3" routerLink="/account"
                        routerLinkActive="active">Konto</a>
                    <!-- <button mat-button (click)="auth.signOut()">Logout</button> -->
                </div>
                <div class="absolute w-full inset-x-0 bottom-0 z-20">
                    <div class="border border-gray-400 mx-5 rounded-full"></div>
                </div>
                <a mat-mini-fab
                    class="mr-2 border border-green-500 bg-white shadow-two rounded-full flex items-center p-2"
                    matTooltip="Termin vereinbaren" matTooltipPosition="before" color="white"
                    aria-label="Calendar button" href="https://calendar.app.google/6riBcYwqvtGyvKBs8" >
                    <mat-icon>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="#32B72F" d="M19.07 13.88L13 19.94V22H15.06L21.12 15.93M22.7 13.58L21.42 12.3C21.32 12.19 21.18 12.13 21.04 12.13C20.89 12.14 20.75 12.19 20.65 12.3L19.65 13.3L21.7 15.3L22.7 14.3C22.89 14.1 22.89 13.78 22.7 13.58M11 18H4V8L12 13L20 8V10H22V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H11V18M20 6L12 11L4 6H20Z" />
                        </svg>
                    </mat-icon>
                </a>
                <div (click)="sidenav.toggle()" class="flex items-center p-2 pt-3 active:outline-none px-3 md:hidden">
                    <mat-icon style="height: 30px;width: 30px;">
                        <svg fill="none" viewBox="0 0 24 19">
                            <path fill="#000"
                                d="M2.5 4h19a1.5 1.5 0 000-3h-19a1.5 1.5 0 100 3zM21.5 8h-14a1.5 1.5 0 100 3h14a1.5 1.5 0 000-3zM21.5 15h-19a1.5 1.5 0 000 3h19a1.5 1.5 0 000-3z" />
                            <path stroke="#000"
                                d="M2.5 4h19a1.5 1.5 0 000-3h-19a1.5 1.5 0 100 3zM21.5 8h-14a1.5 1.5 0 100 3h14a1.5 1.5 0 000-3zM21.5 15h-19a1.5 1.5 0 000 3h19a1.5 1.5 0 000-3z" />
                        </svg>
                    </mat-icon>
                </div>
            </nav>
        </mat-toolbar>
        <main class="mt-16" #scrollContainer>
            <router-outlet></router-outlet>
        </main>
        <ng-container *ngIf="router.url === '/account' || router.url === '/preise' || router.url === '/login' || router.url === '/requestUnsubscription' || router.url === '/page-not-found' || router.url === '/' || router.url.split('/')[1]  === 'confirm' || router.url.split('/')[1]  === 'unsubscribe'; else elseTemplate"></ng-container>
        <ng-template #elseTemplate>
            <footer class="relative" #elseTemplate>
                <section class="relative z-10 bg-gray-200 flex justify-center items-center py-4">
                    <a class="border-2 border-gray-500 text-gray-500 py-2 text-center w-32 mx-2" routerLink="/impress"
                        routerLinkActive="router-link-active">Impressum</a>
                    <a class="border-2 border-gray-500 text-gray-500 py-2 text-center w-32 mx-2" routerLink="/privacy"
                        routerLinkActive="router-link-active">Datenschutz</a>
                    <div class="absolute right-0 mr-5"  routerLink="login">
                        <button mat-outlined-button>Login</button>
                    </div>
                </section>
            </footer>     
        </ng-template>

    </mat-sidenav-content>
</mat-sidenav-container>


<!--
<header
    class="flex justify-between fixed inset-y-0 top-0 h-16 overflow-hidden w-full pl-4 pr-4 z-50 font-semibold font-poppins"
    style="background-color: #F6F6F6;">
    <a routerLink="/home" class="flex items-center">
        <figure class="w-12 h-12 rounded-full overflow-hidden">
            <img class="object-cover h-full w-full" alt="Claudia Fink" src="../assets/images/claudia.jpg" />
        </figure>
        <div class="ml-3 pt-1">
            <div class="text-gray-900 text-xl">Claudia Fink</div>
            <div class="text-green-600">Gesund in Balance</div>
        </div>
    </a>
    <nav class="flex items-center tracking-wide text-gray-600 text-lg">
        <a class="outline-none mx-2 px-4 py-3" routerLink="/home"
            routerLinkActive="active">{{'HOME.HOME' | translate}}</a>
        <div *ngIf="!auth.isLoggedIn; else loggedIn">
            <a class="outline-none mx-2 px-4 py-3" routerLink="/login"
                routerLinkActive="active">{{'HOME.LOGIN' | translate}}</a>
            <a class="outline-none mx-2 px-4 py-3" routerLink="/register"
                routerLinkActive="active">{{'HOME.REGISTER' | translate}}</a>
        </div>
        <div *ngIf="auth.isLoggedIn">
            <a class="outline-none mx-2 px-4 py-3" routerLink="/account"
                routerLinkActive="active">{{'HOME.ACCOUNT' | translate}}</a>
            <button mat-button (click)="auth.signOut()">Logout</button>
        </div>
         <div class="w-10 h-12 overflow-hidden my-auto">
            <div class="relative bg-gray-500 w-10 h-6 p-0 m-0 overflow-hidden">
                <svg *ngIf="selectedLang === 'de'" class="-mt-2" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path fill="#d80027" d="M0 85.331h512v341.337H0z" />
                    <path d="M0 85.331h512v113.775H0z" />
                    <path fill="#ffda44" d="M0 312.882h512v113.775H0z" />
                </svg>
                <svg *ngIf="selectedLang === 'en'" class="-mt-2" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path fill="#f0f0f0" d="M0 85.333h512V426.67H0z" />
                    <path fill="#d80027" d="M288 85.33h-64v138.666H0v64h224v138.666h64V287.996h224v-64H288z" />
                    <path fill="#0052b4"
                        d="M393.785 315.358L512 381.034v-65.676zm-82.133 0L512 426.662v-31.474l-143.693-79.83zm146.982 111.304l-146.982-81.664v81.664z" />
                    <path fill="#f0f0f0" d="M311.652 315.358L512 426.662v-31.474l-143.693-79.83z" />
                    <path fill="#d80027" d="M311.652 315.358L512 426.662v-31.474l-143.693-79.83z" />
                    <path fill="#0052b4" d="M90.341 315.356L0 365.546v-50.19zm110.007 14.154v97.151H25.491z" />
                    <path fill="#d80027" d="M143.693 315.358L0 395.188v31.474l200.348-111.304z" />
                    <path fill="#0052b4"
                        d="M118.215 196.634L0 130.958v65.676zm82.133 0L0 85.33v31.474l143.693 79.83zM53.366 85.33l146.982 81.664V85.33z" />
                    <path fill="#f0f0f0" d="M200.348 196.634L0 85.33v31.474l143.693 79.83z" />
                    <path fill="#d80027" d="M200.348 196.634L0 85.33v31.474l143.693 79.83z" />
                    <path fill="#0052b4" d="M421.659 196.636L512 146.446v50.19zm-110.007-14.154V85.331h174.857z" />
                    <path fill="#d80027" d="M368.307 196.634L512 116.804V85.33L311.652 196.634z" />
                </svg>
            </div>
            <select class="w-full outline-none text-sm" #langSelect
                (change)="translate.use(langSelect.value); selectedLang = langSelect.value">
                <option class="outline-none" *ngFor="let lang of translate.getLangs()" [value]="lang"
                    [selected]="lang === translate.currentLang">
                    {{lang}}</option>
            </select>
        </div>
        <button (click)="openDialog()" mat-mini-fab matTooltip="Termin vereinbaren" matTooltipPosition="before"
            color="white" aria-label="Calendar button">
            <mat-icon>
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="#32B72F"
                        d="M19,3H18V1H16V3H8V1H6V3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H10V19H5V8H19V9H21V5A2,2 0 0,0 19,3M21.7,13.35L20.7,14.35L18.65,12.35L19.65,11.35C19.85,11.14 20.19,11.13 20.42,11.35L21.7,12.63C21.89,12.83 21.89,13.15 21.7,13.35M12,18.94L18.07,12.88L20.12,14.88L14.06,21H12V18.94Z" />
                </svg>
            </mat-icon>
        </button>
    </nav>
    <div class="absolute w-full inset-x-0 bottom-0 z-20">
        <div class="border border-gray-400 mx-5 rounded-full"></div>
    </div>
</header>

<main class="pt-16 relative w-full overflow-hidden">
     <figure class=" absolute right-0 left-0 top-0 w-full max-w-9xl mx-auto">
        <img class="object-cover w-full h-full" src="../assets/images/leaf-squooshed.jpg" alt="Leaf, Background Image">
    </figure>
    <div *ngIf="auth.user$ | async as user">
        {{user | json}}
    </div>
    <section class="relative z-10">
        <router-outlet></router-outlet>
    </section>
</main>

<footer>
    <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a
            href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a
            href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
</footer> -->
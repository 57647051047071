<section>
    <div class="relative z-20 font-poppins text-2xl font-semibold pt-16 text-center">Kontaktieren Sie mich<span
            class="text-green-500">!</span>
    </div>
    <section class="relative z-20 ">
        <div class="flex justify-center mt-8">
            <a href="tel:01607047040">
                <button mat-stroked-button mat-raised-button class="rounded-md bg-white"
                    style="border-radius: 4px; border-color: #32b72f; color: #32b72f">
                    <mat-icon class="mr-3">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor"
                                d="M17.25,18H6.75V4H17.25M14,21H10V20H14M16,1H8A3,3 0 0,0 5,4V20A3,3 0 0,0 8,23H16A3,3 0 0,0 19,20V4A3,3 0 0,0 16,1Z" />
                        </svg>
                    </mat-icon>
                    <span class="text-gray-800">0160-7047040</span>
                </button>
            </a>
        </div>

        <section
            class="relative max-w-5xl md:mx-auto mt-10 border border-gray-400 p-5 rounded-lg overflow-hidden mx-5 md:mx-0">
            <div class="absolute inset-0 bg-white" style="background-color: rgba(rgb(2, 1, 1), white, white, 0.8);"></div>
            <div class="absolute top-0 inset-x-0 w-full mr-5">
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>
            <form [formGroup]="contactForm" #contactFormDirective="ngForm"
                (ngSubmit)="onSubmitContactForm(contactFormDirective)" class="w-full block">
                <section class="xs:grid xs:grid-cols-2 md:grid-cols-3 col-gap-6">
                    <div class="">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Vorname, Nachname <span class="text-green-500">*</span></mat-label>
                            <input class="bg-white" #name formControlName="name" matInput type="text" autocomplete="off"
                                [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Betreff <span class="text-green-500">*</span></mat-label>
                            <input class="bg-white" #subject formControlName="subject" matInput type="text"
                                autocomplete="off" [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Firma</mat-label>
                            <input class="bg-white" #company formControlName="company" matInput type="text"
                                autocomplete="off" [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Email <span class="text-green-500">*</span></mat-label>
                            <input #email formControlName="email" matInput type="email" autocomplete="off"
                                [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Telefon/ Mobil</mat-label>
                            <input class="bg-white" #phone formControlName="phone" matInput type="text"
                                autocomplete="off" [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div class="">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Datum</mat-label>
                            <input class="bg-white" matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" formControlName="date">
                            <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </section>
                <section class="flex">

                </section>
                <section>
                    <div>
                        <!-- <md-input-container fxFlex="18" fxFlexOffset="1" appearance="outline">
                            <textarea [(ngModel)]="Comments" name="Comments" mdInput placeholder="Comments" rows="3"></textarea>
                              <md-error>This field is required</md-error>
                          </md-input-container> -->
                        <!-- <mat-form-field class="w-full" appearance="outline" fxFlex="18" fxFlexOffset="1">
                            <mat-label>Nachricht <span class="text-green-500">*</span></mat-label>
                            <textarea #message formControlName="message" matInput type="text" autocomplete="off"
                                [errorStateMatcher]="matcher" name="Comments" rows="9"></textarea>
                        </mat-form-field> -->
                        <ckeditor
                            [config]="{placeholder:'Geben Sie hier Ihre Nachricht ein. *', toolbar: [ 'heading', '|', 'bold', 'italic' ] }"
                            class="col-span-12" formControlName="message"
                            [editor]="Editor1" style="min-height: 400px !important;" ></ckeditor>
                    </div>
                </section>
                <section class="flex mt-5">
                    <div
                        class="md:block hidden bg-white mt-2 max-w-element.tags[0]5xl mx-auto font-lato text-md text-gray-900 relative z-20 ml-0 text-gray-800 font-medium flex items-center rounded-sm px-2">
                        Alternativ kann auch direkt eine Mail an <a class="font-base text-blue-600"
                            style="color: #32b72f;"
                            href="mailto:gesundinbalance@gmx.de?subject=Kontaktform&amp;body=">&nbsp;gesundinbalance@gmx.de&nbsp;</a>
                        gesendet werden.</div>
                    <div class="ml-auto mr-0">
                        <button mat-raised-button type="submit" [disabled]="!contactForm.valid" class="text-white tracking-wider"
                            style="border: 1px solid #32b72f; background-color: #32b72f;">Senden</button>
                    </div>
                </section>
            </form>
        </section>

    </section>
    <section class="relative z-20 mt-20 pb-20">
        <div class="relative z-20 font-poppins text-2xl font-semibold text-center">Wegbeschreibung</div>
        <section class="flex mt-8">
            <div class="mx-auto">
                <iframe width="600" height="450" frameborder="0" style="border:0"
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJhaxojp6nvUcRqqcrfBiBwBc&key=AIzaSyBlLg4Uud1FiVm3rQM2kh8HNTlYxxhPUKw"
                    allowfullscreen></iframe>
                <!-- <iframe width="600" height="300" class="bg-gray-300"
                    src="http://maps.google.de/maps?key=AIzaSyBlLg4Uud1FiVm3rQM2kh8HNTlYxxhPUKw&?hl=de&q=In+den+Weingärten+120,+65760+Eschborn&ie=UTF8&t=&z=14&iwloc=B&maptype=roadmap&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a
                        href="http://www.siteway.de/maps-generator/"
                        title="Google Maps Gesundheitsberatung Eschborn">(c)
                        Homepage Google Maps Generator</a>
                </iframe> -->
            </div>
        </section>
    </section>
    <section class="fixed inset-0 z-10">
        <figure class="w-full h-full">
            <img class="w-full h-full object-cover lg:mt-16" src="../assets/images/price.jpg" alt="">
        </figure>
        <div class="fixed inset-0 z-10 bg-white opacity-50"></div>
    </section>
    <!-- <section class="fixed z-10 inset-x-0 bottom-0"> -->
    <!-- <div class="bg-white opacity-25 fixed z-10 inset-0"></div> -->
    <!-- <figure class="w-full h-full">
            <img class="w-full h-full object-cover" src="../assets/images/hills.jpg" alt="">
        </figure>
    </section> -->
</section>
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/shared/classes/custom.error-matcher';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/service/firebase.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  matcher = new CustomErrorStateMatcher();
  error: Observable<string>;
  loading: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private fireService: FirebaseService,
    private _snackBar: MatSnackBar
  ) {
    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25)
      ]],
    })
  }

  ngOnInit(): void {
  }

  get emailLoginForm() { return this.loginForm.get('email') }
  get passwordLoginForm() { return this.loginForm.get('password') }

  async onSubmitLoginForm(formDirective: FormGroupDirective) {
    if (this.loginForm.valid) {
      this.loading = true;
      await this.fireService.signIn(this.emailLoginForm.value, this.passwordLoginForm.value);
      formDirective.resetForm();
      this.loginForm.reset();
      this.loading = false;
    }
  }

  openSnackBar(title: string, text: string, color: string) {
    this._snackBar.openFromComponent(LoginSnackBarComponent, {
      duration: 7000,
      data: { title, text },
      panelClass: [color],
      verticalPosition: 'bottom'
    });
  }

}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      {{data.title}} <br/>
      <div class="mt-2 font-lato font-normal text-base text-grey-800">{{data.text}}</div>
    </div>
  `
})
export class LoginSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}



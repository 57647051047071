import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReviewDialogComponent } from 'src/app/components/dialogs/review-dialog/review-dialog.component';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { CustomErrorStateMatcher } from 'src/app/shared/classes/custom.error-matcher';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/service/firebase.service';
import * as firebase from 'firebase/app'
import 'firebase/firestore'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  newsletterForm: FormGroup = this.fb.group({
    email: ['', [
      Validators.required,
      Validators.email,
      Validators.minLength(5)
    ]]
  });
  get newsletterFormEmail() { return this.newsletterForm.get('email') }

  data$: any;

  constructor(
    public fireService: FirebaseService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
    // private afs: AngularFirestore
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.fireService.loadReviews();
    this.fireService.loadHomepageData();
    this.fireService.homepage$.subscribe(data => {
      this.data$ = data;
    })
  }

  matcher = new CustomErrorStateMatcher();
  loading: boolean = false;
  toggleOfferCardInput: any = {};
  toggleCooperationInput: any = {};
  toggleTitle;
  toggleSubtitle;
  reviews$;
  toggleMottoTitle;
  toggleMottoText;
  toggleEdit;

  async onSave() {
    this.fireService.updateHomepageData(this.data$);
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(ReviewDialogComponent, {
      width: '320px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async onSubmitNewsletter(formDirective: FormGroupDirective) {
    if (this.newsletterForm.valid) {
      this.loading = true;
      // console.log(this.newsletterFormEmail.value)
      await this.fireService.subscribeToNewsletter(this.newsletterFormEmail.value)
      this.loading = false;
    };
    formDirective.resetForm();
    this.newsletterForm.reset();
  }

  openSnackBar(title: string, text: string, color: string) {
    this._snackBar.openFromComponent(NewsletterSnackBarComponent, {
      duration: 7000,
      data: { title, text },
      panelClass: [color],
      verticalPosition: 'bottom'
    });
  }
}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      {{data.title}} <br/>
      <div class="mt-2 font-lato font-normal text-base text-grey-800">{{data.text}}</div>
    </div>
  `
})

export class NewsletterSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}


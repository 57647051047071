<section>
    <div class="relative z-20 font-poppins text-2xl font-semibold pt-16 text-center">Mein Konto<span
            class="text-green-500">.</span>
    </div>
    <div class="lg2:grid-cols-4 gap-5 sm:grid lg:grid-rows-1 grid-rows-2 mt-8 px-5 lg:grid-cols-12 sm:grid-cols-2">

        <div class="lg2:col-span-1 lg:col-span-4">
            <div class="bg-white flex flex-col items-center h-176 overflow-y-scroll ">
                <div class="bg-white">
                    <table mat-table [dataSource]="dataSource" class="">
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation();"
                                    (change)="$event ? selection.toggle(row) : null; selection.isSelected(row) ? addToNewsletter(row): removeFromNewsletter(row);"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Position Column -->
                        <ng-container matColumnDef="timestamp">
                            <th mat-header-cell *matHeaderCellDef class="hidden"> Time </th>
                            <td mat-cell *matCellDef="let element" class=" hidden">
                                {{element.timestamp}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="email" class="px-5">
                            <th mat-header-cell *matHeaderCellDef class="px-5"> Emails </th>
                            <td mat-cell *matCellDef="let element" class="px-5"> {{element.email}} </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="confirmed">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="text-center">
                                    {{dataSource.filteredData.length}}
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon class="text-green-500">check</mat-icon>
                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <!-- <ng-container matColumnDef="symbol">
                        <th mat-header-cell *matHeaderCellDef> Symbol </th>
                        <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                    </ng-container> -->

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div>
            <!-- <app-email-table (listOutput)="handleList($event)" (listAddOutput)="handleListAdd($event)"
                    (listRemoveOutput)="handleListRemove($event)"></app-email-table> -->
        </div>
        <div class="bg-white h-176 overflow-hidden lg2:col-span-1 lg:col-span-3 sm:my-0 my-5">
            <div class="p-5">
                <div class=" text-xl font-medium mb-2">Neuen Code hinzufügen</div>
                <div>
                    <form [formGroup]="codeForm" #codeFormDirective="ngForm"
                        (ngSubmit)="onSubmitCodeForm(codeFormDirective)">
                        <p>
                            <mat-form-field class="w-full" appearance="outline">
                                <mat-label>Code eingeben</mat-label>
                                <input #input minlength="6" matInput type="number" placeholder="123456"
                                    formControlName="code" [errorStateMatcher]="matcher">
                                <mat-hint align="end">{{input.value?.length || 0}}/6</mat-hint>
                            </mat-form-field>
                        </p>
                        <p>
                            <mat-form-field class="w-full" appearance="outline">
                                <mat-label>Für wen ist der Code</mat-label>
                                <input matInput placeholder="Name der Person" formControlName="for"
                                    [errorStateMatcher]="matcher">
                            </mat-form-field>
                        </p>
                        <button mat-raised-button type="submit" [disabled]="!codeForm.valid"
                            class="bg-green-500 text-white text-base font-poppins">Abschicken</button>
                    </form>
                </div>
                <div class="mt-8 w-full  h-96 relative">
                    <div class="h-full w-full overflow-y-scroll" style="padding-right:-17px; box-sizing: content-box;">
                        <ul>
                            <li *ngFor="let code of fireService.codes$ | async">
                                <div
                                    class="flex justify-between items-center w-full pr-5 border border-r-0 border-t-0 border-l-0 py-2">
                                    <div>
                                        <div>Code: <span class="font-medium text-md">{{code.code}}</span></div>
                                        <div>Für: <span>{{code.for}}</span></div>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="!code.used; else elseTemplate">
                                            <mat-chip-list>
                                                <mat-chip
                                                    style="border:1px solid green; background-color: #fff; color:black;"
                                                    selected>Verfügbar</mat-chip>
                                            </mat-chip-list>
                                        </ng-container>
                                        <ng-template #elseTemplate>
                                            <mat-chip-list>
                                                <mat-chip>Verbraucht</mat-chip>
                                            </mat-chip-list>
                                        </ng-template>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="lg2:col-span-2 lg:col-span-5 col-span-2 ">
            <ng-container class="bg-white " *ngIf="layout; else elseTemplate">
                <div *ngIf="layout === 1" class="anim-up bg-white p-5 shadow-four">
                    <div class="font-poppins text-xl font-semibold text-center pb-8 text-gray-700">Layout {{layout}}
                    </div>
                    <div class=" grid grid-cols-12 gap-3">
                        <label for="to" class="col-span-1 self-center font-thin  text-sm text-gray-900">An</label>
                        <div
                            class="col-span-11 py-1 truncate px-3 bg-white border border-gray-300 bg-gray-100 text-gray-700 my-2 leading-tight focus:border-blue-500">
                            {{newsletterList |json}}
                        </div>
                        <label for="subject"
                            class=" col-span-1 self-center font-thin  text-sm text-gray-900 block;">Betreff</label>
                        <input [(ngModel)]="subject"
                            class=" col-span-11 py-1 px-3 bg-white border border-gray-500 text-gray-700 my-2 leading-tight focus:border-blue-500"
                            type="text" name="subject">
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ]}"
                            class="col-span-12" [ngModelOptions]="{standalone: true}" [(ngModel)]="model.editorData1"
                            [editor]="Editor1"></ckeditor>
                        <div class="grid grid-cols-3 gap-3 col-span-12 py-5">
                            <input accept="image/*" #file_input_one type="file" style="display:none"
                                (change)="getSrcOne($event)">
                            <button
                                class=" relative border border-gray-400 hover:border-blue-500 trans hover:shadow-two overflow-hidden  h-48"
                                style="padding:0px; margin:0px;" (click)="file_input_one.click()">
                                <mat-icon *ngIf="!src_one; else image_ref_one">image</mat-icon>
                                <ng-template #image_ref_one>
                                    <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_one" alt="" />
                                </ng-template>
                            </button>
                            <input accept="image/*" #file_input_two type="file" style="display:none"
                                (change)=" getSrcTwo($event)">
                            <button
                                class=" overflow-hidden border border-gray-400 hover:border-blue-500 trans hover:shadow-two overflow-hidden h-48"
                                style="padding:0px; margin:0px;" (click)="file_input_two.click()">
                                <mat-icon *ngIf="!src_two; else image_ref_two">image</mat-icon>
                                <ng-template #image_ref_two>
                                    <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_two" alt="" />
                                </ng-template>
                            </button>
                            <input accept="image/*" #file_input_three type="file" style="display:none"
                                (change)=" getSrcThree($event)">
                            <button
                                class=" overflow-hidden border border-gray-400 hover:border-blue-500 trans hover:shadow-two overflow-hidden h-48"
                                style="padding:0px; margin:0px;" (click)="file_input_three.click()">
                                <mat-icon *ngIf="!src_three; else image_ref_three">image</mat-icon>
                                <ng-template #image_ref_three>
                                    <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_three"
                                        alt="" />
                                </ng-template>
                            </button>
                        </div>
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-12" [ngModelOptions]="{standalone: true}" [(ngModel)]="model.editorData2"
                            [editor]="Editor2">
                        </ckeditor>
                        <div class=" col-span-12 pt-6 xs:flex justify-between  xs:flex-row flex-col">
                            <button mat-raised-button (click)="clearFields()"
                                class=" col-span-2 bg-red-500 text-white text-base font-poppins mb-3 xs:mb-0">Abbrechen</button>
                            <button mat-raised-button type="submit" (click)="sendTestEmail()"
                                [disabled]="!newsletterList || newsletterList.length === 0 || !subject"
                                class=" col-start-7 col-span-2 bg-blue-500 text-white text-base font-poppins mb-3 xs:mb-0">Test-Email</button>
                            <button mat-raised-button
                                [disabled]="!newsletterList || newsletterList.length === 0 || !subject"
                                (click)="onSubmitEmailForm()"
                                class=" col-start-11 col-span-2 bg-green-500 text-white text-base font-poppins mb-3 xs:mb-0">Email
                                Senden</button>
                        </div>
                        <!-- <button (click)="uploadEmail()">Upload</button> -->

                    </div>
                </div>
                <div *ngIf="layout === 2" class="anim-up bg-white p-5 shadow-four">
                    <div class="font-poppins text-xl font-semibold text-center pb-8 text-gray-700">Layout {{layout}}
                    </div>
                    <div class=" grid grid-cols-12 gap-3">
                        <label for="to" class="col-span-1 self-center font-thin  text-sm text-gray-900">An</label>
                        <div
                            class="col-span-11 py-1 truncate px-3 bg-white border border-gray-300 bg-gray-100 text-gray-700 my-2 leading-tight focus:border-blue-500">
                            {{newsletterList |json}}
                        </div>
                        <label for="subject"
                            class=" col-span-1 self-center font-thin  text-sm text-gray-900 block;">Betreff</label>
                        <input [(ngModel)]="subject"
                            class=" col-span-11 py-1 px-3 bg-white border border-gray-500 text-gray-700 my-2 leading-tight focus:border-blue-500"
                            type="text" name="subject">
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-12 h-full h-48" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="model.editorData1" [editor]="Editor1"></ckeditor>
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-8 h-full h-48" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="model.editorData2" [editor]="Editor2"></ckeditor>
                        <!-- <div class="grid grid-cols-3 gap-3 col-span-12 py-5"> -->
                        <input accept="image/*" #file_input_one type="file" style="display:none"
                            (change)="getSrcOne($event)">
                        <button
                            class=" relative col-span-4 border border-gray-400 hover:border-blue-500 trans hover:shadow-two overflow-hidden  h-48"
                            style="padding:0px; margin:0px;" (click)="file_input_one.click()">
                            <mat-icon *ngIf="!src_one; else image_ref_one">image</mat-icon>
                            <ng-template #image_ref_one>
                                <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_one" alt="" />
                            </ng-template>
                        </button>
                        <input accept="image/*" #file_input_two type="file" style="display:none"
                            (change)=" getSrcTwo($event)">
                        <button
                            class=" col-span-8 overflow-hidden border border-gray-400 hover:border-blue-500 trans hover:shadow-two overflow-hidden h-48"
                            style="padding:0px; margin:0px;" (click)="file_input_two.click()">
                            <mat-icon *ngIf="!src_two; else image_ref_two">image</mat-icon>
                            <ng-template #image_ref_two>
                                <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_two" alt="" />
                            </ng-template>
                        </button>
                        <input accept="image/*" #file_input_three type="file" style="display:none"
                            (change)=" getSrcThree($event)">
                        <button
                            class=" col-span-4 overflow-hidden border border-gray-400 hover:border-blue-500 trans hover:shadow-two overflow-hidden h-48"
                            style="padding:0px; margin:0px;" (click)="file_input_three.click()">
                            <mat-icon *ngIf="!src_three; else image_ref_three">image</mat-icon>
                            <ng-template #image_ref_three>
                                <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_three" alt="" />
                            </ng-template>
                        </button>
                        <!-- </div> -->
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-12" [ngModelOptions]="{standalone: true}" [(ngModel)]="model.editorData3"
                            [editor]="Editor3">
                        </ckeditor>
                        <div class=" col-span-12 pt-6 xs:flex justify-between  xs:flex-row flex-col">
                            <button mat-raised-button (click)="clearFields()"
                                class=" col-span-2 bg-red-500 text-white text-base font-poppins mb-3 xs:mb-0">Abbrechen</button>
                            <button mat-raised-button type="submit" (click)="sendTestEmail()"
                                [disabled]="!newsletterList || newsletterList.length === 0 || !subject"
                                class=" col-start-7 col-span-2 bg-blue-500 text-white text-base font-poppins mb-3 xs:mb-0">Test-Email</button>
                            <button mat-raised-button
                                [disabled]="!newsletterList || newsletterList.length === 0 || !subject"
                                (click)="onSubmitEmailForm()"
                                class=" col-start-11 col-span-2 bg-green-500 text-white text-base font-poppins mb-3 xs:mb-0">Email
                                Senden</button>
                        </div>
                        <!-- <button (click)="uploadEmail()">Upload</button> -->

                    </div>
                </div>
                <div *ngIf="layout === 3" class="anim-up bg-white p-5 shadow-four ">
                    <div class="font-poppins text-xl font-semibold text-center pb-8 text-gray-700">Layout {{layout}}
                    </div>
                    <div class=" grid grid-cols-12 gap-3">
                        <label for="to" class="col-span-1 self-center font-thin  text-sm text-gray-900">An</label>
                        <div
                            class="col-span-11 py-1 truncate px-3 bg-white border border-gray-300 bg-gray-100 text-gray-700 my-2 leading-tight focus:border-blue-500">
                            {{newsletterList |json}}
                        </div>
                        <label for="subject"
                            class=" col-span-1 self-center font-thin  text-sm text-gray-900 block;">Betreff</label>
                        <input [(ngModel)]="subject"
                            class=" col-span-11 py-1 px-3 bg-white border border-gray-500 text-gray-700 my-2 leading-tight focus:border-blue-500"
                            type="text" name="subject">
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-12 h-full h-48" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="model.editorData1" [editor]="Editor1"></ckeditor>
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-8 h-full h-48" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="model.editorData2" [editor]="Editor2"></ckeditor>
                        <!-- <div class="grid grid-cols-3 gap-3 col-span-12 py-5"> -->
                        <input accept="image/*" #file_input_one type="file" style="display:none"
                            (change)="getSrcOne($event)">
                        <button
                            class=" relative col-span-4 border-gray-400 hover:border-blue-500 trans hover:shadow-two border overflow-hidden  h-48"
                            style="padding:0px; margin:0px;" (click)="file_input_one.click()">
                            <mat-icon *ngIf="!src_one; else image_ref_one">image</mat-icon>
                            <ng-template #image_ref_one>
                                <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_one" alt="" />
                            </ng-template>
                        </button>
                        <input accept="image/*" #file_input_two type="file" style="display:none"
                            (change)=" getSrcTwo($event)">
                        <button
                            class="col-span-4 overflow-hidden border border-gray-400 hover:border-blue-500 trans hover:shadow-two overflow-hidden h-48"
                            style="padding:0px; margin:0px;" (click)="file_input_two.click()">
                            <mat-icon *ngIf="!src_two; else image_ref_two">image</mat-icon>
                            <ng-template #image_ref_two>
                                <img class="object-cover rounded-md overflow-hidden h-48" [src]="src_two" alt="" />
                            </ng-template>
                        </button>

                        <!-- </div> -->
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-8" [ngModelOptions]="{standalone: true}" [(ngModel)]="model.editorData3"
                            [editor]="Editor3">
                        </ckeditor>
                        <ckeditor
                            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'insertTable', '|', 'undo', 'redo' ] }"
                            class="col-span-12" [ngModelOptions]="{standalone: true}" [(ngModel)]="model.editorData4"
                            [editor]="Editor4">
                        </ckeditor>
                        <div class=" col-span-12 pt-6 xs:flex justify-between xs:flex-row flex-col">
                            <button mat-raised-button (click)="clearFields()"
                                class=" col-span-2 bg-red-500 text-white text-base font-poppins mb-3 xs:mb-0">Abbrechen</button>
                            <button mat-raised-button type="submit" (click)="sendTestEmail()"
                                [disabled]="!newsletterList || newsletterList.length === 0 || !subject"
                                class=" col-start-7 col-span-2 bg-blue-500 text-white text-base font-poppins mb-3 xs:mb-0">Test-Email</button>
                            <button mat-raised-button
                                [disabled]="!newsletterList || newsletterList.length === 0 || !subject"
                                (click)="onSubmitEmailForm()"
                                class=" col-start-11 col-span-2 bg-green-500 text-white text-base font-poppins mb-3 xs:mb-0">Email
                                Senden</button>
                        </div>

                        <!-- <button (click)="uploadEmail()">Upload</button> -->
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="lg:h-176 lg:overflow-y-scroll lg:overflow-x-hidden overflow-x-auto">
                    <div class="lg:grid grid-cols-2 lg2:grid-cols-3 row-gap-5 lg:mx-auto flex">
                        <div *ngFor="let item of fireService.reviews$ | async" class="mx-auto lg:mr-0 mr-6">
                            <div class="bg-white p-3 rounded-sm shadow-one  h-96 overflow-hidden w-full"
                                style="min-width: 250px; max-width: 250px;">
                                <figure class="w-24 h-24 mx-auto rounded-full overflow-hidden ">
                                    <img class="w-full h-full object-cover" [src]="item.url" alt="Gesicht">
                                </figure>
                                <div class="p-px bg-green-500 mx-5 rounded-full mt-2"></div>
                                <div class="text-gray-700 text-center font-lato mt-5 px-5">
                                    <div class="font-medium text-lg"> {{item.header}} </div>
                                    <div class="mt-3"> {{item.main}} </div>
                                    <div class="mt-4"> {{item.footer}} </div>
                                </div>
                            </div>
                            <div class="flex justify-center items-center mt-2">
                                <button class="w-full" mat-stroked-button
                                    (click)="changeVisibility(item.id, item.public)"
                                    [color]="item.public ? 'warn': 'primary'">{{item.public ? 'Freigabe aufheben': 'Freigeben'}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="flex mt-5">
        <div class="bg-white flex flex-col items-center p-5">
            <div class="font-poppins text-xl font-semibold pb-8">Layouts</div>
            <div class="grid lg2:grid-rows-1 grid-cols-3 lg2:grid-cols-1 gap-3 lg:gap-10">
                <button mat-raised-button (click)="layout = lout.number" *ngFor="let lout of layouts">
                    <figure class="">
                        <img [src]="lout.src" alt="lout.alt">
                    </figure>
                </button>
            </div>
        </div>
    </div>
 
    <div class="pb-5 absolute right-0 top-0 mt-20 z-40 mr-5"><button mat-raised-button mat-button class="bg-red-400 text-white"
            (click)="fireService.signOut()">Logout</button></div>
    <div class="pb-5 absolute right-0 top-0 mt-20 z-40 mr-32"><button mat-raised-button mat-button class="bg-green-500 text-white cursor-pointer"
            (click)="generateCSVFile()">Export Emails!</button></div>
    <div class="pb-5 absolute right-0 top-0 mt-20 z-40 mr-64 pr-5 pt-2 flex items-center"><a class="text-blue-500" href="https://drive.google.com/file/d/1ojM048fV95TgC479PQC-SrRYUKkAgMpb/view?usp=sharing">Link zum Tutorial</a></div>
</section>
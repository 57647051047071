<section class="overflow-x-hidden relative">
    <div class="fixed top-0 mt-16 right-0 z-40 pt-5 pr-8">
        <button color="primary" mat-raised-button *ngIf="fireService.user$ | async as user"
            (click)="onSave()">Speichern</button>
    </div>
    <section class="px-5 md:px-8 font-poppins font-semibold relative z-10 text-center">
        <div class="md:mr-auto md:ml-0 md:max-w-md md:text-left">
            <div class="text-gray-500 font-semibold text-sm sm:mt-2">
                <!-- staatlich geprüfte -->
            </div>
            <div class="text-gray-500 font-semibold text-base">
                <!-- Gesundheitsberaterin & Gesundheitspädagogin -->
            </div>
        </div>
    </section>
    <section class="mt-16">
        <div class="text-center font-semibold font-poppins text-3xl text-gray-800">Ernährungsberatung</div>
        <div class="mt-8 font-marker text-gray-700 text-center text-lg">Die Basis unseres Seins ist die Ernährung.</div>
    </section>
    <section class="flex max-w-7xl mx-auto">
        <figure class="w-3/12 -mt-20">
            <img src="../assets/images/fruits.jpg" alt="">
        </figure>
        <div class="text-gray-600 font-lato mt-32 text-xl w-8/12">
            <div class="max-w-2lg tracking-wider leading-7 mx-auto">Da wir alle unterschiedlich sind und jeder einen
                anderen Lebensrhythmus, Ziele und Vorgeschichten hat,
                gibt es nicht das Standardrezept.
                <div class="relative">
                    <div class="absolute top-0 right-0 z-50">
                        <button mat-mini-fab [color]="toggleErnaehrungsInput ? 'accent': 'primary'"
                            *ngIf="fireService.user$ | async as user" (click)="toggleErnaehrungsInput = !toggleErnaehrungsInput">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <ng-container *ngIf="toggleErnaehrungsInput; else elseTemplateOne">
                        <input type="text" name="" [(ngModel)]="data$?.ernaehrung.price.info"
                            class="border flex items-center w-40 border-pink-600 relative z-40 mt-8">
                        <input type="text" name="" [(ngModel)]="data$?.ernaehrung.price.price"
                            class="border flex items-center w-10 border-pink-600 relative z-40 mt-2">
                    </ng-container>
                    <ng-template #elseTemplateOne>
                        <div class="mt-8">
                            {{data$?.ernaehrung.price.info}}
                        </div>
                        <div class=" mt-2">
                            {{data$?.ernaehrung.price.price}} <span class=" mr-2" >€</span> 
                        </div>
                    </ng-template>
                </div>
            </div>
            <!-- <div
                class="p-8 mt-20 ml-auto w-96 shadow-two text-center tracking-wide leading-6 overflow-hidden rounded-lg bg-white">
                <div>
                    In herausfordernden Zeiten kann es sinnvoll sein, den Stoffwechsel durch natürliche supplements und
                    add-ons zu unterstützen.
                </div>
                <div class="mt-6 mb-16"><span class="font-semibold text-gray-700">Lifewave</span> beitet hierfür
                    hochwertige Nahrungsergänzungsmittel an.</div>
                <a href="" class="">
                    <button mat-stroked-button class="w-full text-green-500 text-xl"
                        style="border-color: #32b72f; padding:6px">Zum Shop</button>
                </a>
            </div> -->
        </div>
    </section>
    <section class="flex max-w-7xl mx-auto mt-10">
        <div class="w-6/12 tracking-wider leading-7 my-auto font-lato  text-gray-600 text-xl">Die Ernährungsformen
            dieser Erde sind unendlich vielfältig und reichhaltig. Sie sind aus regionalen,
            kulturellen, religiösen oder auch medizinischen und sportlich motivierten Hintergründen entstanden. Die
            Ziele dieser Ernährungsformen sind von Gesundheit über Empowerment bis zu Reduktionsdiäten ebenso
            vielfältig.</div>
        <figure class="w-6/12 -mt-32">
            <img src="../assets/images/toasts.jpg" alt="">
        </figure>
    </section>
    <section class="mt-10 mb-20">
        <figure class="w-96 mx-auto">
            <img src="../assets/images/various.jpg" alt="">
        </figure>
        <div class="font-marker text-xl text-gray-600 text-center">Ich berate, begleite und unterstütze Sie auf ihrem
            individuellen Weg.</div>
        <div class="flex justify-center mt-16">
            <a mat-stroked-button class="bg-white w-64 hover:shadow-three text-gray-700 text-xl border-2 text-center"
                style="border-color: #32b72f; padding:6px; border-radius: 2px;" href="https://calendar.app.google/6riBcYwqvtGyvKBs8">
                <span class="mr-2">Termin anfragen</span>
                <mat-icon>
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="#32B72F" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                    </svg>
                </mat-icon>
            </a>
        </div>
    </section>
</section>
<div>
    <div class="relative z-20 font-poppins text-2xl font-semibold xs:pt-16 text-center pt-6">Bewegung</div>
    <section class="relative z-20 mt-5 lg:max-w-5xl lg:mx-auto md:mx-20 xs:mx-8 pb-20">
        <div class="bg-gray-400 p-px mx-auto w-64"></div>
        <div class="text-center max-w-5xl mx-auto mt-4 text-gray-700 tracking-wide text-md xs:text-lg font-medium leading-6 font-nunito xs:font-lato px-5">
            Die entspanntesten und natürlichsten Bewegungen sind die Schönsten. Ihr besten Werke die ungezwungensten
            "Montaigne". Erfahren Sie die pure Freude an der Bewegungsform, die zu Ihnen und Ihrem Lebensrhythmus passt.
            Ich begleite und motiviere Sie auf diesem Weg.
        </div>
        <div class="bg-gray-400 p-px mx-auto w-64 mt-4"></div>
        <section class="mt-10">
            <app-offer-card  *ngFor="let offer of offers" [offer]="offer"></app-offer-card>
        </section>
    </section>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Basic Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// Internationalization Components
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Angular Firebase Components 
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
// Angular Material Components 
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
//Components
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { AdminComponent } from './views/admin/admin.component';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { ReviewDialogComponent } from './components/dialogs/review-dialog/review-dialog.component';
import { AppointmentDialogComponent } from './components/dialogs/appointment-dialog/appointment-dialog.component';
import { AccountComponent } from './views/account/account.component';
import { AboutComponent } from './views/about/about.component';
import { PricesComponent } from './views/prices/prices.component';
import { environment } from 'src/environments/environment';
import { RehabsportsComponent } from './views/rehabsports/rehabsports.component';
import { ImpressComponent } from './views/impress/impress.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { ErnaehrungComponent } from './views/ernaehrung/ernaehrung.component';
import { BesuchshundComponent } from './views/besuchshund/besuchshund.component';
import { ContactComponent } from './views/contact/contact.component';
import { EntspannungComponent } from './views/entspannung/entspannung.component';
import { PaymentCardComponent } from './components/basic/payment-card/payment-card.component';
import { OfferCardComponent } from './components/basic/offer-card/offer-card.component';
import { RelaxationCardComponent } from './components/basic/relaxation-card/relaxation-card.component';
import { FerienhausComponent } from './views/ferienhaus/ferienhaus.component';
import { BetrieblichegesundheitComponent } from './views/betrieblichegesundheit/betrieblichegesundheit.component';
import { ConfirmComponent } from './views/confirm/confirm.component';
// import { EmailTableComponent } from './components/basic/email-table/email-table.component';
import { UnsubscribeComponent } from './views/unsubscribe/unsubscribe.component';
import { EditButtonComponent } from './components/basic/edit-button/edit-button.component';

import * as firebase from 'firebase/app';
import { RequestUnsubscriptionComponent } from './views/request-unsubscription/request-unsubscription.component';
firebase.initializeApp(environment.FIREBASE_CONFIG);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    PageNotFoundComponent,
    AdminComponent,
    ReviewDialogComponent,
    AppointmentDialogComponent,
    AccountComponent,
    AboutComponent,
    PricesComponent,
    RehabsportsComponent,
    ImpressComponent,
    PrivacyComponent,
    ErnaehrungComponent,
    BesuchshundComponent,
    ContactComponent,
    EntspannungComponent,
    PaymentCardComponent,
    OfferCardComponent,
    RelaxationCardComponent,
    FerienhausComponent,
    BetrieblichegesundheitComponent,
    ConfirmComponent,
    // EmailTableComponent,
    UnsubscribeComponent,
    EditButtonComponent,
    RequestUnsubscriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,

    //Firebase Modules
    // AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),

    // Material Modules
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRippleModule,
    MatChipsModule,
    MatTableModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatRadioModule,
    MatMenuModule,
    MatTabsModule,
    MatListModule,
    MatExpansionModule,
    MatCheckboxModule,

    //CKEditor
    CKEditorModule,
    
    // Internationalization
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    // { provide: REGION, useValue: 'europe-west2' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

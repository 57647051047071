<h1 mat-dialog-title>Termin anfragen</h1>
<div class="absolute top-0 inset-x-0 w-full mr-5">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>
<div mat-dialog-content>
    <form [formGroup]="appointmentForm" #appointmentFormDirective="ngForm"
        (ngSubmit)="onSubmitAppointmentForm(appointmentFormDirective)" class="w-full block">
        <div>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Vor- und Nachname<span class="text-green-500">*</span></mat-label>
                <input #name formControlName="name" matInput type="name" autocomplete="off"
                    [errorStateMatcher]="matcher">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Email eingeben<span class="text-green-500">*</span></mat-label>
                <input #email formControlName="email" matInput type="email" autocomplete="off"
                    [errorStateMatcher]="matcher">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Datum auswählen<span class="text-green-500">*</span></mat-label>
                <input matInput formControlName="date"  [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="grid grid-cols-2 gap-3">
            <div>
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Von <span class="text-green-500">*</span></mat-label>
                    <input #from formControlName="from" matInput type="text" autocomplete="off" placeholder="hh:mm"
                        [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Bis <span class="text-green-500">*</span></mat-label>
                    <input #to formControlName="to" matInput type="text" autocomplete="off" placeholder="hh:mm"
                        [errorStateMatcher]="matcher">
                </mat-form-field>
            </div>
        </div>
        <div>
            <mat-form-field appearance="outline" class="w-full">
                <mat-label>Angebot auswählen<span class="text-green-500">*</span></mat-label>
                <mat-select [formControl]="offer">
                    <mat-option *ngFor="let offer of offers" [value]="offer.viewValue">
                        {{offer.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex justify-between pb-3">
            <button mat-button (click)="onNoClick()">Abbrechen</button>
            <button type="submit" [disabled]="!appointmentForm.valid"
                class="shadow-three font-medium bg-green-500 px-3 py-2 text-white rounded-md overflow-hidden"
                [ngClass]="{'bg-gray-500': !appointmentForm.valid, 'text-black': !appointmentForm.valid}">Anfrage
                absenden</button>
        </div>
    </form>
</div>
<section class="px-5 md:px-8 font-poppins font-semibold relative z-10 text-center ml-40">
    <div class="md:mr-auto md:ml-0 md:max-w-md md:text-left">
        <div class="text-gray-500 font-semibold text-sm sm:mt-2">staatlich geprüfte</div>
        <div class="text-gray-500 font-semibold text-base">Gesundheitsberaterin & Gesundheitspädagogin</div>
    </div>
</section>
<div class="relative z-20 font-poppins text-2xl font-semibold pt-16 text-center">Newsletter abbestellen<span
        class="text-green-500">.</span>
</div>
<section class="py-24">
    <div class="relative w-full max-w-xl mx-auto shadow-two rounded-sm overflow-hidden p-5">
        <div class="absolute top-0 inset-x-0 w-full">
            <mat-progress-bar color="green" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="!loading; else resp">
            <div class="leading-6 text-center">Es tut mir leid das Sie den Newsletter abbestellen. <br>
                <div class="text-lg pt-4">Um den Newsletter abzubestellen, geben Sie bitte in das nachfolgende Feld Ihre Email ein. </div>
                <div class="text-xs pt-4 mx-10">Sie erhalten in der Fogle einen Link per Email. Dieser stellt sicher dass Sie es sind. Klicken Sie dort drauf und Sie sind abgemeldet.</div>
            </div>
            <div class="mt-8">
                        <div class="absolute top-0 inset-x-0 w-full mr-5">
                            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                        </div>
                        <mat-form-field class="w-full pb-0 mb-0">
                            <mat-label>Newsletter abbestellen</mat-label>
                            <input placeholder="Geben Sie Ihre Email-Adresse hier ein" autocomplete="off" matInput type="email" [(ngModel)]="msg">
                        </mat-form-field>
                <div class="mt-2 flex justify-end">
                    <button mat-raised-button (click)="unsubscribe()"
                        class="bg-green-500 text-white font-lato font-semibold">Abbestellen</button>
                </div>
            </div>
        </div>
        <ng-template #resp>
            <div class="py-5 text-center font-lato text-lg font-medium">
                Email wird überprüft!
            </div>
        </ng-template>

    </div>
</section>
import { Injectable } from '@angular/core';
// import { AngularFireFunctions } from '@angular/fire/functions';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  constructor(
    // private functions: AngularFireFunctions
  ) { }

  // makeModerator(email: string) {
  //   const callable = this.functions.httpsCallable('makeModerator');
  //   callable({ email: email }).pipe(take(1)).subscribe(
  //     message => // // console.log(message),
  //     error => // console.error(error)
  //   )
  // }

  register(displayname: string, email: string, password: string) {
    // const callable = this.functions.httpsCallable('register');
    // return callable({ displayName: displayname, email: email, password: password });
  }
}

<section>
    <section class="px-5 md:px-8 font-poppins font-semibold relative z-10 text-center ml-40">
        <div class="md:mr-auto md:ml-0 md:max-w-md md:text-left">
            <div class="text-gray-500 font-semibold text-sm sm:mt-2">staatlich geprüfte</div>
            <div class="text-gray-500 font-semibold text-base">Gesundheitsberaterin & Gesundheitspädagogin</div>
        </div>
    </section>
    <div class="relative z-20 font-poppins text-2xl font-semibold pt-16 text-center">Email Bestätigung<span
            class="text-green-500">.</span>
    </div>
    <section class="py-24">
        <div class="relative w-64 mx-auto shadow-two rounded-sm overflow-hidden p-5">
            <div class="absolute top-0 inset-x-0 w-full">
                <mat-progress-bar color="green" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf="loading; else message" class="py-5 text-center font-lato text-lg font-medium">
                Ihre Email wird verifiziert
            </div>
            <ng-template #message>
                <div *ngIf="success; else failed"
                    class="font-poppins text-lg text-green-500 text-center font-semibold tracking-wide">
                    Email verifiziert!
                    <div class="font-lato text-base font-base">
                        {{successMessage}}
                    </div>
                    <div class="mt-5">
                        <a href="https://gesundheitsberatung-eschborn.web.app/home" class="text-blue-500 mt-5">Zur
                            Homepage
                        </a>
                    </div>
                </div>
                <ng-template #failed>
                    <ng-container *ngIf="infoMessage; else elseTemplate">
                        <div class="py-5 text-md text-green-500">Ihre Email ist bereits verifiziert.</div>
                        <div class="text-gray-700">Sie erhalten ab sofort den Newsletter. <a
                                href="https://gesundheitsberatung-eschborn.web.app/home" class="text-blue-500">Zur
                                Homepage</a>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="py-5 text-red-500">Ihre Email konnte nicht verifiziert werden, da der eingegebene
                            Token
                            ungültig ist. </div>
                        <div class="py-5 text-gray-600">Um den Newsletter zu abonnieren klicken sie auf den folgenden
                            Link.
                            <a href="https://gesundheitsberatung-eschborn.web.app/home" class="text-blue-500">Zur
                                Homepage</a></div>
                    </ng-template>

                </ng-template>

            </ng-template>
        </div>
    </section>
</section>
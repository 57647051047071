<main class="w-full max-w-5xl mx-auto">
        <section class="pt-16 pb-10">
                <div class="text-center font-semibold font-poppins text-3xl text-gray-800">Datenschutz</div>
        </section>
        <a></a>
        <div>
                <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden;">
                </div>
        </div>
        <div>

        </div>
        <div>
                <div>
                        <div data-index="0" style="opacity: 1; ">
                                <div>
                                        <blockquote>
                                                <div><img alt="" /></div>
                                                <div><b>1. Datenschutz auf einen Blick</b></div>
                                                <div><b><span>Allgemeine Hinweise</span></b></div>
                                                <div><span>Die folgenden Hinweise geben einen einfachen Überblick
                                                                darüber, was
                                                                mit Ihren personenbezogenen Daten passiert, wenn Sie
                                                                unsere Website besuchen.
                                                                Personenbezogene Daten sind alle Daten, mit denen Sie
                                                                persönlich identifiziert werden
                                                                können. Ausführliche Informationen zum Thema Datenschutz
                                                                entnehmen Sie unserer unter
                                                                diesem Text aufgeführten Datenschutzerklärung.</span>
                                                </div>
                                                <div> &nbsp;</div>
                                                <div><b><span>Datenerfassung auf unserer Website</span></b></div>
                                                <div><b><span>Wer ist verantwortlich für die Datenerfassung auf dieser
                                                                        Website?</span></b></div>
                                                <div><span>Die Datenverarbeitung auf dieser Website erfolgt durch den
                                                                Websitebetreiber. Dessen Kontaktdaten können Sie dem
                                                                Impressum dieser Website
                                                                entnehmen.</span></div>
                                                <div><b><span>Wie erfassen wir Ihre Daten?</span></b></div>
                                                <div><span>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
                                                                diese
                                                                mitteilen. Hierbei kann es sich z.B. um Daten handeln,
                                                                die Sie in ein Kontaktformular
                                                                eingeben.</span></div>
                                                <div><span>Andere Daten werden automatisch beim Besuch der Website durch
                                                                unsere IT-Systeme erfasst. Das sind vor allem technische
                                                                Daten (z.B. Internetbrowser,
                                                                Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                                                                Erfassung dieser Daten erfolgt
                                                                automatisch, sobald Sie unsere Website betreten.</span>
                                                </div>
                                                <div><b><span>Wofür nutzen wir Ihre Daten?</span></b></div>
                                                <div><span>Ein Teil der Daten wird erhoben, um eine fehlerfreie
                                                                Bereitstellung
                                                                der Website zu gewährleisten. Andere Daten können zur
                                                                Analyse Ihres Nutzerverhaltens
                                                                verwendet werden.</span></div>
                                                <div><b><span>Welche Rechte haben Sie bezüglich Ihrer Daten?</span></b>
                                                </div>
                                                <div><span>Sie haben jederzeit das Recht unentgeltlich Auskunft über
                                                                Herkunft,
                                                                Empfänger und Zweck Ihrer gespeicherten
                                                                personenbezogenen Daten zu erhalten. Sie haben
                                                                außerdem ein Recht, die Berichtigung, Sperrung oder
                                                                Löschung dieser Daten zu verlangen.
                                                                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
                                                                können Sie sich jederzeit unter
                                                                der im Impressum angegebenen Adresse an uns wenden. Des
                                                                Weiteren steht Ihnen ein
                                                                Beschwerderecht bei der zuständigen Aufsichtsbehörde
                                                                zu.</span></div>
                                                <div><span> </span></div>
                                                <div> &nbsp;</div>
                                                <div><b><span>2. Allgemeine Hinweise und Pflichtinformationen</span></b>
                                                </div>
                                                <div><b><span>Datenschutz</span></b></div>
                                                <div><span>Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                                                                persönlichen
                                                                Daten sehr ernst. Wir behandeln Ihre personenbezogenen
                                                                Daten vertraulich und
                                                                entsprechend der gesetzlichen Datenschutzvorschriften
                                                                sowie dieser
                                                                Datenschutzerklärung.</span></div>
                                                <div><span>Wenn Sie diese Website benutzen, werden verschiedene
                                                                personenbezogene Daten erhoben. Personenbezogene Daten
                                                                sind Daten, mit denen Sie
                                                                persönlich identifiziert werden können. Die vorliegende
                                                                Datenschutzerklärung erläutert,
                                                                welche Daten wir erheben und wofür wir sie nutzen. Sie
                                                                erläutert auch, wie und zu
                                                                welchem Zweck das geschieht.</span></div>
                                                <div><span>Wir weisen darauf hin, dass die Datenübertragung im Internet
                                                                (z.B.
                                                                bei der Kommunikation per E-Mail) Sicherheitslücken
                                                                aufweisen kann. Ein lückenloser
                                                                Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                                                                möglich.</span></div>
                                                <div><span> </span></div>
                                                <div> &nbsp;</div>
                                                <div><b><span>Hinweis zur verantwortlichen Stelle</span></b></div>
                                                <div><span>Die verantwortliche Stelle für die Datenverarbeitung auf
                                                                dieser
                                                                Website ist:</span></div>
                                        </blockquote>
                                        <blockquote>
                                                <blockquote>
                                                        <div><span>Claudia Nauheim</span></div>
                                                        <div><span>staatlich geprüfte Gesundheitsberaterin &amp;
                                                                        Gesundheitspädagogin<br> In den Weingärten
                                                                        120</span></div>
                                                        <div><span>65760 Eschborn</span></div>
                                                        <div><span>Mobil: 0160-7047040</span></div>
                                                        <div><span>EMail: </span>
                                                                <span><a
                                                                                href="mailto:gesundinbalance@gmx.de">gesundinbalance@gmx.de</a></span>
                                                        </div>
                                                </blockquote>
                                                <blockquote>
                                                        <div></div>
                                                </blockquote>
                                        </blockquote>
                                        <blockquote>
                                                <div><span> Verantwortliche Stelle ist die natürliche oder juristische
                                                                Person,
                                                                die allein oder gemeinsam mit anderen über die Zwecke
                                                                und Mittel der Verarbeitung von
                                                                personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
                                                                Ä.) entscheidet.</span></div>
                                                <div><b><span>Widerruf Ihrer Einwilligung zur
                                                                        Datenverarbeitung</span></b>
                                                </div>
                                                <div><span>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                                                                ausdrücklichen
                                                                Einwilligung möglich. Sie können eine bereits erteilte
                                                                Einwilligung jederzeit
                                                                widerrufen. Dazu reicht eine formlose Mitteilung per
                                                                E-Mail an uns. Die Rechtmäßigkeit
                                                                der bis zum Widerruf erfolgten Datenverarbeitung bleibt
                                                                vom Widerruf unberührt.</span>
                                                </div>
                                                <div><b><span>Beschwerderecht bei der zuständigen
                                                                        Aufsichtsbehörde</span></b>
                                                </div>
                                                <div><span>Im Falle datenschutzrechtlicher Verstöße steht dem
                                                                Betroffenen ein
                                                                Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                                                                Zuständige Aufsichtsbehörde in
                                                                datenschutzrechtlichen Fragen ist der
                                                                Landesdatenschutzbeauftragte des Bundeslandes, in
                                                                dem unser Unternehmen seinen Sitz hat. Eine Liste der
                                                                Datenschutzbeauftragten sowie
                                                                deren Kontaktdaten können folgendem Link entnommen
                                                                werden:
                                                                <span>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</span>.<br><br></span>
                                                </div>
                                                <div><b><span>Recht auf Datenübertragbarkeit</span></b></div>
                                                <div><span>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                                                                Einwilligung oder in Erfüllung eines Vertrags
                                                                automatisiert verarbeiten, an sich oder an
                                                                einen Dritten in einem gängigen, maschinenlesbaren
                                                                Format aushändigen zu lassen. Sofern
                                                                Sie die direkte Übertragung der Daten an einen anderen
                                                                Verantwortlichen verlangen,
                                                                erfolgt dies nur, soweit es technisch machbar
                                                                ist.</span></div>
                                                <div><span> </span></div>
                                                <div> &nbsp;</div>
                                                <div><b><span>SSL- bzw. TLS-Verschlüsselung</span></b></div>
                                                <div><span>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                                                                Übertragung vertraulicher Inhalte, wie zum Beispiel
                                                                Bestellungen oder Anfragen, die Sie
                                                                an uns als Seitenbetreiber senden, eine SSL-bzw.
                                                                TLS-Verschlüsselung. Eine
                                                                verschlüsselte Verbindung erkennen Sie daran, dass die
                                                                Adresszeile des Browsers von
                                                                “http://” auf “https://” wechselt und an dem
                                                                Schloss-Symbol in Ihrer
                                                                Browserzeile.</span></div>
                                                <div><span>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können
                                                                die
                                                                Daten, die Sie an uns übermitteln, nicht von Dritten
                                                                mitgelesen werden.</span></div>
                                                <div><br></div>
                                                <div><b><span>Auskunft, Sperrung, Löschung</span></b></div>
                                                <div><span>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                                                                jederzeit das Recht auf unentgeltliche Auskunft über
                                                                Ihre gespeicherten
                                                                personenbezogenen Daten, deren Herkunft und Empfänger
                                                                und den Zweck der
                                                                Datenverarbeitung und ggf. ein Recht auf Berichtigung,
                                                                Sperrung oder Löschung dieser
                                                                Daten. Hierzu sowie zu weiteren Fragen zum Thema
                                                                personenbezogene Daten können Sie sich
                                                                jederzeit unter der im Impressum angegebenen Adresse an
                                                                uns wenden.</span></div>
                                                <div> &nbsp;</div>
                                                <div><b><span>Widerspruch gegen Werbe-Mails</span></b></div>
                                                <div><span>Der Nutzung von im Rahmen der Impressumspflicht
                                                                veröffentlichten
                                                                Kontaktdaten zur Übersendung von nicht ausdrücklich
                                                                angeforderter Werbung und
                                                                Informationsmaterialien wird hiermit widersprochen. Die
                                                                Betreiber der Seiten behalten
                                                                sich ausdrücklich rechtliche Schritte im Falle der
                                                                unverlangten Zusendung von
                                                                Werbeinformationen, etwa durch Spam-E-Mails, vor.</span>
                                                </div>
                                                <div><br></div>
                                                <div><b><span>3. Datenerfassung auf unserer Website</span></b></div>
                                                <div><b><span>Cookies</span></b></div>
                                                <div><span>Die Internetseiten verwenden teilweise so genannte Cookies.
                                                                Cookies
                                                                richten auf Ihrem Rechner keinen Schaden an und
                                                                enthalten keine Viren. Cookies dienen
                                                                dazu, unser Angebot nutzerfreundlicher, effektiver und
                                                                sicherer zu machen. Cookies sind
                                                                kleine Textdateien, die auf Ihrem Rechner abgelegt
                                                                werden und die Ihr Browser
                                                                speichert.</span><span> </span></div>
                                                <div><span>Die meisten der von uns verwendeten Cookies sind so genannte
                                                                “Session-Cookies”. Sie werden nach Ende Ihres Besuchs
                                                                automatisch gelöscht. Andere
                                                                Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
                                                                diese löschen. Diese Cookies
                                                                ermöglichen es uns, Ihren Browser beim nächsten Besuch
                                                                wiederzuerkennen.</span></div>
                                                <div><span>Sie können Ihren Browser so einstellen, dass Sie über das
                                                                Setzen
                                                                von Cookies informiert werden und Cookies nur im
                                                                Einzelfall erlauben, die Annahme von
                                                                Cookies für bestimmte Fälle oder generell ausschließen
                                                                sowie das automatische Löschen
                                                                der Cookies beim Schließen des Browser aktivieren. Bei
                                                                der Deaktivierung von Cookies
                                                                kann die Funktionalität dieser Website eingeschränkt
                                                                sein.</span></div>
                                                <div><span>Cookies, die zur Durchführung des elektronischen
                                                                Kommunikationsvorgangs oder zur Bereitstellung
                                                                bestimmter, von Ihnen erwünschter
                                                                Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
                                                                werden auf Grundlage von Art. 6
                                                                Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber
                                                                hat ein berechtigtes Interesse an
                                                                der Speicherung von Cookies zur technisch fehlerfreien
                                                                und optimierten Bereitstellung
                                                                seiner Dienste. Soweit andere Cookies (z.B. Cookies zur
                                                                Analyse Ihres Surfverhaltens)
                                                                gespeichert werden, werden diese in dieser
                                                                Datenschutzerklärung gesondert
                                                                behandelt.<br></span><span> </span></div>
                                                <div><b><span>Server-Log-Dateien</span></b></div>
                                                <div><span>Der Provider der Seiten erhebt und speichert automatisch
                                                                Informationen in so genannten Server-Log-Dateien, die
                                                                Ihr Browser automatisch an uns
                                                                übermittelt. Dies sind:</span></div>
                                                <div>
                                                        <!--[if !supportLists]--><span>·
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <!--[endif]-->Browsertyp und Browserversion</span></div>
                                                <div>
                                                        <!--[if !supportLists]--><span>·
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <!--[endif]-->verwendetes Betriebssystem</span></div>
                                                <div>
                                                        <!--[if !supportLists]--><span>·
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <!--[endif]-->Referrer URL</span></div>
                                                <div>
                                                        <!--[if !supportLists]--><span>·
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <!--[endif]-->Hostname des zugreifenden Rechners</span>
                                                </div>
                                                <div>
                                                        <!--[if !supportLists]--><span>·
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <!--[endif]-->Uhrzeit der Serveranfrage</span></div>
                                                <div>
                                                        <!--[if !supportLists]--><span>·
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <!--[endif]-->IP-Adresse</span></div>
                                                <div><span>Eine Zusammenführung dieser Daten mit anderen Datenquellen
                                                                wird
                                                                nicht vorgenommen.</span></div>
                                                <div><span>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
                                                                DSGVO,
                                                                der die Verarbeitung von Daten zur Erfüllung eines
                                                                Vertrags oder vorvertraglicher
                                                                Maßnahmen gestattet.<br></span><span> </span></div>
                                                <div><b><span>Kontaktformular</span></b></div>
                                                <div><span>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                                                                werden
                                                                Ihre Angaben aus dem Anfrageformular inklusive der von
                                                                Ihnen dort angegebenen
                                                                Kontaktdaten zwecks Bearbeitung der Anfrage und für den
                                                                Fall von Anschlussfragen bei uns
                                                                gespeichert. Diese Daten geben wir nicht ohne Ihre
                                                                Einwilligung weiter.</span></div>
                                                <div><span>Die Verarbeitung der in das Kontaktformular eingegebenen
                                                                Daten
                                                                erfolgt somit ausschließlich auf Grundlage Ihrer
                                                                Einwilligung (Art. 6 Abs. 1 lit. a
                                                                DSGVO). Sie können diese Einwilligung jederzeit
                                                                widerrufen. Dazu reicht eine formlose
                                                                Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis
                                                                zum Widerruf erfolgten
                                                                Datenverarbeitungsvorgänge bleibt vom Widerruf
                                                                unberührt.</span></div>
                                                <div><span>Die von Ihnen im Kontaktformular eingegebenen Daten
                                                                verbleiben bei
                                                                uns, bis Sie uns zur Löschung auffordern, Ihre
                                                                Einwilligung zur Speicherung widerrufen
                                                                oder der Zweck für die Datenspeicherung entfällt (z.B.
                                                                nach abgeschlossener Bearbeitung
                                                                Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
                                                                insbesondere Aufbewahrungsfristen –
                                                                bleiben unberührt.</span></div>
                                                <div> &nbsp;</div>
                                                <div><b><span>Registrierung auf dieser Website</span></b></div>
                                                <div><span>Sie können sich auf unserer Website registrieren, um
                                                                zusätzliche
                                                                Funktionen auf der Seite zu nutzen. Die dazu
                                                                eingegebenen Daten verwenden wir nur zum
                                                                Zwecke der Nutzung des jeweiligen Angebotes oder
                                                                Dienstes, für den Sie sich registriert
                                                                haben. Die bei der Registrierung abgefragten
                                                                Pflichtangaben müssen vollständig angegeben
                                                                werden. Anderenfalls werden wir die Registrierung
                                                                ablehnen.</span></div>
                                                <div><span>Für wichtige Änderungen etwa beim Angebotsumfang oder bei
                                                                technisch
                                                                notwendigen Änderungen nutzen wir die bei der
                                                                Registrierung angegebene E-Mail-Adresse,
                                                                um Sie auf diesem Wege zu informieren.</span></div>
                                                <div><span>Die Verarbeitung der bei der Registrierung eingegebenen Daten
                                                                erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
                                                                lit. a DSGVO). Sie können eine
                                                                von Ihnen erteilte Einwilligung jederzeit widerrufen.
                                                                Dazu reicht eine formlose
                                                                Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der
                                                                bereits erfolgten Datenverarbeitung
                                                                bleibt vom Widerruf unberührt.</span></div>
                                                <div><span>Die bei der Registrierung erfassten Daten werden von uns
                                                                gespeichert, solange Sie auf unserer Website registriert
                                                                sind und werden anschließend
                                                                gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
                                                                unberührt.</span></div>
                                                <div> &nbsp;</div>
                                                <div><b><span>4. Plugins und Tools</span></b></div>
                                                <div> &nbsp;</div>
                                                <div><b><span>YouTube</span></b></div>
                                                <div><span>Unsere Website nutzt Plugins der von Google betriebenen Seite
                                                                YouTube. Betreiber der Seiten ist die YouTube, LLC, 901
                                                                Cherry Ave., San Bruno, CA
                                                                94066, USA.</span></div>
                                                <div><span>Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten
                                                                Seiten besuchen, wird eine Verbindung zu den Servern von
                                                                YouTube hergestellt. Dabei wird
                                                                dem YouTube-Server mitgeteilt, welche unserer Seiten Sie
                                                                besucht haben.</span></div>
                                                <div><span>Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
                                                                ermöglichen Sie
                                                                YouTube, Ihr Surfverhalten direkt Ihrem persönlichen
                                                                Profil zuzuordnen. Dies können Sie
                                                                verhindern, indem Sie sich aus Ihrem YouTube-Account
                                                                ausloggen.</span></div>
                                                <div><span>Die Nutzung von YouTube erfolgt im Interesse einer
                                                                ansprechenden
                                                                Darstellung unserer Online-Angebote. Dies stellt ein
                                                                berechtigtes Interesse im Sinne von
                                                                Art. 6 Abs. 1 lit. f DSGVO dar.</span></div>
                                                <div><span>Weitere Informationen zum Umgang mit Nutzerdaten finden Sie
                                                                in der
                                                                Datenschutzerklärung von YouTube unter:
                                                                <span>https://www.google.de/intl/de/policies/privacy</span>.</span>
                                                </div>
                                                <div> </div>
                                                <div><b> Google Maps</b></div>
                                                <div><span>Diese Seite nutzt über eine API den Kartendienst Google Maps.
                                                                Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
                                                                Mountain View, CA 94043,
                                                                USA.</span></div>
                                                <div><span>Zur Nutzung der Funktionen von Google Maps ist es notwendig,
                                                                Ihre
                                                                IP Adresse zu speichern. Diese Informationen werden in
                                                                der Regel an einen Server von
                                                                Google in den USA übertragen und dort gespeichert. Der
                                                                Anbieter dieser Seite hat keinen
                                                                Einfluss auf diese Datenübertragung</span><span> </span>
                                                </div>
                                                <div><span>Die Nutzung von Google Maps erfolgt im Interesse einer
                                                                ansprechenden Darstellung unserer Online-Angebote und an
                                                                einer leichten Auffindbarkeit
                                                                der von uns auf der Website angegebenen Orte. Dies
                                                                stellt ein berechtigtes Interesse im
                                                                Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</span></div>
                                                <div><span>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in
                                                                der
                                                                Datenschutzerklärung von Google:
                                                                <span>https://www.google.de/intl/de/policies/privacy/</span>.</span>
                                                </div>
                                                <div></div>
                                        </blockquote>
                                        <div>
                                                <div> &nbsp;</div>
                                        </div>
                                </div>
                        </div>

                </div>
        </div>
</main>
<section class="px-5 md:px-8 font-poppins font-semibold relative z-10 text-center ml-40">
    <div class="md:mr-auto md:ml-0 md:max-w-md md:text-left">
        <div class="text-gray-500 font-semibold text-sm sm:mt-2">staatlich geprüfte</div>
        <div class="text-gray-500 font-semibold text-base">Gesundheitsberaterin & Gesundheitspädagogin</div>
    </div>
</section>
<div class="relative z-20 font-poppins text-2xl font-semibold pt-16 pb-10 text-center">Die Seite die Sie suchen
    existiert nicht<span class="text-green-500">.</span>
    <div class="pt-10">
        <a href="https://gesundheitsberatung-eschborn.web.app/home" class="text-lg font-semibold text-blue-500">Zur
            Homepage</a>
    </div>

</div>
<main class="w-full max-w-5xl mx-auto">
    <section class="pt-16 pb-10">
        <div class="text-center font-semibold font-poppins text-3xl text-gray-800">Impressum</div>
    </section>
    <div>
        <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden;">
            <div data-parallax-zoom="140"></div>
        </div>
    </div>
    <div>
        <div>
            <div data-index="0" style="opacity: 1; ">
                <div>
                    <div>
                        <span>.</span></div>
                </div>
            </div>

        </div>
    </div>
    <div>
        <div>
            <div data-index="0" style="opacity: 1; ">
                <div>
                    <blockquote>
                        <div>
                            <img src="images/erecht24-siegel-impressum-blau.png" title="" alt="" /><br>
                        </div>
                        <div>
                            <div><b><span>Impressum</span></b></div>
                            <div><span>Angaben gemäß § 5 TMG:</span>
                                <span> </span>
                            </div>
                            <div>
                                <div>
                                    <div><span>Claudia Fink</span></div>
                                    <div><span>staatlich geprüfte Gesundheitsberaterin &amp;
                                            Gesundheitspädagogin</span><br><span>In den Weingärten
                                            120</span></div>
                                    <div><span>65760 Eschborn</span></div>
                                </div> &nbsp;
                            </div>
                            <div><b>Kontakt:</b></div>
                            <div><span>Telefon: +49 (0)</span><span>160-7047040</span></div>
                            <div><span>E-Mail:</span>
                                <span><a
                                        href="mailto:gesundinbalance@gmx.de?">gesundinbalance@gmx.de</a></span><span>
                                    <br></span><span><br></span></div>
                            <div><b>Angaben zur Berufshaftpflichtversicherung</b></div>
                            <div><span>Name und Sitz des Versicherers: </span><span>Allianz, Königinstraße 28, 80802
                                    München</span><span>
                                    <br></span><span>Geltungsraum der Versicherung:
                                    Deutschland</span><span><br></span></div>
                            <div><span><br>Die Europäische Kommission stellt eine Plattform zur
                                    Online-Streitbeilegung (OS) bereit:</span></div>
                            <div><span>https://ec.europa.eu/consumers/odr.</span></div>
                            <div><span>Unsere E-Mail-Adresse finden Sie oben im Impressum.</span>
                            </div>
                            <div><span>Wir sind nicht bereit oder verpflichtet, an
                                    Streitbeilegungsverfahren vor einer </span><span>Verbraucherschlichtungsstelle
                                    teilzunehmen.</span>
                                <div><br></div>
                            </div>
                            <div><b>Haftung für Inhalte</b></div>
                            <div><span>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                                    Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                                    bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                                    gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die
                                    auf eine rechtswidrige Tätigkeit hinweisen.</span></div>
                            <div><span>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                                    Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                                    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                                    werden wir diese Inhalte umgehend entfernen.</span></div>
                            <div><span> &nbsp;</span></div>
                            <div><b>Haftung für Links</b></div>
                            <div><span>Unser Angebot enthält Links zu externen Websites Dritter, auf
                                    deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
                                    Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
                                    stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                                    verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                                    überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                                    erkennbar.</span></div>
                            <div><span>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                                    Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                                    entfernen.</span></div>
                            <div><span> &nbsp;</span></div>
                            <div><b>Urheberrecht</b></div>
                            <div><span>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                                    diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                                    Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
                                    Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                                    Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                                    kommerziellen Gebrauch gestattet.</span></div>
                            <div><span>Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                                    erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
                                    Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                                    Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                                    umgehend entfernen.</span></div>
                            <div><span>Quelle:
                                    <span>
                                    </span><span>https://www.e-recht24.de/impressum-generator.html<br></span></span><span><br>Quellenangaben
                                    für die verwendeten Bilder und
                                    Grafiken:<br></span>
                                <div><span>
                                        <a href="https://www.pexels.com"
                                            onclick="return x5engine.imShowBox({ media:[{type: 'iframe', url: 'https://www.pexels.com', width: 1920, height: 1080, description: ''}]}, 0, this);">https://www.pexels.com</a></span>
                                </div>
                                <div><span>
                                        <a href="https://pixabay.com"
                                            onclick="return x5engine.imShowBox({ media:[{type: 'iframe', url: 'https://pixabay.com', width: 1920, height: 1080, description: ''}]}, 0, this);">https://pixabay.com</a><br></span>
                                    <div><span><a href="https://de.123rf.com"
                                                onclick="return x5engine.imShowBox({ media:[{type: 'iframe', url: 'https://de.123rf.com', width: 1920, height: 1080, description: ''}]}, 0, this);">https://de.123rf.com</a></span>
                                    </div>
                                </div>
                            </div>
                            <div><span> &nbsp;</span></div>
                        </div>
                    </blockquote>
                    <div></div>
                </div>
            </div>

        </div>
    </div>
</main>
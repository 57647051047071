import { Component, OnInit, Input } from '@angular/core';
import { CloseScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit {

  constructor() { }

  @Input() offer; 

  ngOnInit(): void {
  }

  over() {
    // // console.log('over')
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from 'src/app/service/firebase.service';


@Component({
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss']
})
export class ReviewDialogComponent implements OnInit {

  url = null;
  header = '';
  main = '';
  footer = '';
  code = null;
  loading: boolean = false;
  src_one: any;
  imageOne: any = null;
  base64: any;

  constructor(
    public dialogRef: MatDialogRef<ReviewDialogComponent>,
    private _snackBar: MatSnackBar,
    private fireService: FirebaseService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  async uploadReview() {
    try {
      if (this.footer !== '' && this.header !== '' && this.main !== '' && this.code !== null, this.imageOne !== null) {
        this.loading = true;
        await this.fireService.postReview(this.code, this.header, this.footer, this.main, { base64: this.src_one, fileData: { type: this.imageOne.type } })
        this.url = null;
        this.header = '';
        this.main = '';
        this.footer = '';
        this.code = null;
        this.imageOne = null;
        this.src_one = null;
        this.loading = false;
        this.onNoClick();
      }
    } catch (error) {
      // console.log(error)
      this.openSnackBar('Code ungültig!', '', 'bg-red-500')
    }

  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async getSrcOne(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // this.base64 = await this.toBase64(fileInput)
      // // console.log('Base64',this.base64)
      // console.log(fileInput)
      this.imageOne = fileInput.target.files[0];
      // console.log(this.imageOne)
      // console.log(this.imageOne.type)
      let reader = new FileReader();
      reader.onload = ((e) => {
        this.src_one = e.target.result;
        // console.log(this.src_one.split('base64,')[1])
        // console.log('srcone', this.src_one)
      });
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  openSnackBar(title: string, text: string, color: string) {
    this._snackBar.openFromComponent(NewsletterSnackBarComponent, {
      duration: 7000,
      data: { title, text },
      panelClass: [color],
      verticalPosition: 'bottom'
    });
  }
}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      {{data.title}} <br/>
      <div class="mt-2 font-lato font-normal text-base text-grey-800">{{data.text}}</div>
    </div>
  `
})
export class NewsletterSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}


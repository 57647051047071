import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/shared/classes/custom.error-matcher';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/service/firebase.service';

export interface Offer {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-appointment-dialog',
  templateUrl: './appointment-dialog.component.html',
  styleUrls: ['./appointment-dialog.component.scss']
})
export class AppointmentDialogComponent implements OnInit {
  appointmentForm: FormGroup;
  matcher = new CustomErrorStateMatcher();
  error: Observable<string>;
  loading: boolean = false;
  offers: Offer[] = [
    { value: '0', viewValue: 'Klangschalen-Behandlung' },
    { value: '1', viewValue: 'Autogenes-Training' },
    { value: '2', viewValue: 'Progressive Muskelrelaxation' },
    { value: '3', viewValue: 'Myofasziale Triggerpunkt-Behandlung' },
    { value: '4', viewValue: 'Meditation' }
  ];

  constructor(
    public dialogRef: MatDialogRef<AppointmentDialogComponent>,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private fireService: FirebaseService
  ) {
    this.appointmentForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      name: ['', [
        Validators.required,
      ]],
      from: ['', [
        Validators.required,
        Validators.pattern('[0-9][0-9]?:[0-9][0-9]')
      ]],
      to: ['', [
        Validators.required,
        Validators.pattern('[0-9][0-9]?:[0-9][0-9]')
      ]],
      offer: ['', [
        Validators.required,
      ]],
      date: ['', [
        Validators.required
      ]]
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  get email() { return this.appointmentForm.get('email') }
  get name() { return this.appointmentForm.get('name') }
  get from() { return this.appointmentForm.get('from') }
  get to() { return this.appointmentForm.get('to') }
  get offer() { return this.appointmentForm.get('offer') }
  get date() { return this.appointmentForm.get('date') }


  async onSubmitAppointmentForm(formDirective: FormGroupDirective) {
    if (this.appointmentForm.valid) {
      try {
        this.loading = true;
        let res = await this.fireService.postAppointment(this.email.value, this.name.value, this.from.value, this.to.value, this.offer.value, (new Date(this.date.value)).toLocaleDateString());
        if (res.success) {
          this.dialogRef.close();
          this.loading = false;
        }
        this.dialogRef.close();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.onNoClick()
        this.openSnackBar('Es ist ein Fehler aufgetreten.', 'Bitte verwenden Sie die unten stehende Email. Wir versuchen das Problem so schnell wie möglich zu beheben.', 'bg-red-500')
      }
    };
    formDirective.resetForm();
    this.appointmentForm.reset();
  }
  openSnackBar(title: string, text: string, color: string) {
    this._snackBar.openFromComponent(NewsletterSnackBarComponent, {
      duration: 7000,
      data: { title, text },
      panelClass: [color],
      verticalPosition: 'bottom'
    });
  }
}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      {{data.title}} <br/>
      <div class="mt-2 font-lato font-normal text-base text-grey-800">{{data.text}}</div>
    </div>
  `
})
export class NewsletterSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}


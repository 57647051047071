<div class="bg-white" style="background-color: #F6F6F6;">
    <div class="max-w-md mx-auto py-16 relative z-30">


        <mat-card style="padding-top: 40px; padding-bottom: 40px;" class="overflow-hidden">
            <div class="absolute top-0 inset-x-0 w-full">
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>
            <form [formGroup]="registerForm" #registerFormDirective="ngForm"
                (ngSubmit)="onSubmitRegisterForm(registerFormDirective)" class="w-full block">
                <mat-card-header>
                    <mat-card-title class="font-poppins font-semibold text-gray-800 pb-3">Register</mat-card-title>
                </mat-card-header>
                <mat-card-content class="w-full block px-3">
                    <div>
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Vor- und Nachname</mat-label>
                            <input #name formControlName="name" matInput type="text" [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Email</mat-label>
                            <input #email formControlName="email"  matInput type="email" [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Passwort</mat-label>
                            <input #password formControlName="password" type="password" autocomplete="new-password"
                                matInput [errorStateMatcher]="matcher">
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <div class="px-5 flex justify-between">
                        <a routerLink="/home">
                            <button type="button" mat-button>Zurück</button>
                        </a>
                        <button mat-raised-button type="submit" [disabled]="!registerForm.valid"
                            class="bg-green-500 text-white text-base font-poppins">Registrieren</button>
                    </div>
                </mat-card-actions>
            </form>
            <div *ngIf="error.show" class="px-3 text-red-400">
                {{error.message}}
            </div>
            <div *ngIf="success.show" class="px-3 text-green-400">
                {{success.message}}
            </div>
        </mat-card>
    </div>
</div>
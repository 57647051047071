import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/shared/classes/custom.error-matcher';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { FirebaseService } from 'src/app/service/firebase.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public Editor1 = ClassicEditor;
  public model = {
    editorData: ''
  };

  formData = {
    name: '',
    subject: '',
    company: '',
    email: '',
    mobileNumber: '',
    date: '',
    message: '',
  }

  contactForm: FormGroup;
  matcher = new CustomErrorStateMatcher();
  loading: boolean;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private fireService: FirebaseService,
  ) {
    this.contactForm = this.fb.group({
      name: ['', [
        Validators.required
      ]],
      company: ['', [
      ]],
      subject: ['', [
        Validators.required
      ]],
      message: ['', [
        Validators.required
      ]],
      phone: ['', [
      ]],
      date: ['', [
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
    })
  }

  resetFormData() {
    this.formData = {
      name: '',
      subject: '',
      company: '',
      email: '',
      mobileNumber: '',
      date: '',
      message: '',
    }
  }

  get name() { return this.contactForm.get('name') };
  get subject() { return this.contactForm.get('subject') };
  get company() { return this.contactForm.get('company') };
  get email() { return this.contactForm.get('email') };
  get mobileNumber() { return this.contactForm.get('phone') };
  get date() { return this.contactForm.get('date') };
  get message() { return this.contactForm.get('message') };

  dateIsValid() {
    return (this.contactForm.controls['date'].hasError('matDatepickerMin') && this.contactForm.controls['date'].hasError('matDatepickerMax') && this.contactForm.controls['date'].hasError('matDatepickerFilter')) || this.contactForm.controls['date'].value === '';
  }

  async onSubmitContactForm(formDirective: FormGroupDirective) {
    if (this.contactForm.valid) {
      this.loading = true;
      this.formData.name = this.name.value;
      this.formData.subject = this.subject.value;
      this.formData.company = this.company.value;
      this.formData.email = this.email.value;
      this.formData.mobileNumber = this.mobileNumber.value;
      this.formData.date = (new Date(this.date.value)).toLocaleDateString();
      this.formData.message = this.message.value;
      try {
        await this.fireService.postContact(this.formData);
      } catch (error) {
        this.openSnackBar('Es ist ein Fehler aufgetreten.', 'Bitte verwenden Sie die unten stehende Email. Wir versuchen das Problem so schnell wie möglich zu beheben.', 'bg-red-500')
      }
      this.contactForm.reset();
      formDirective.reset();
      this.resetFormData();
      this.loading = false
    }
  }

  ngOnInit(): void {
  }

  openSnackBar(title: string, text: string, color: string) {
    this._snackBar.openFromComponent(NewsletterSnackBarComponent, {
      duration: 7000,
      data: { title, text },
      panelClass: [color],
      verticalPosition: 'bottom'
    });
  }
}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      {{data.title}} <br/>
      <div class="mt-2 font-lato font-normal text-base text-grey-800">{{data.text}}</div>
    </div>
  `
})
export class NewsletterSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}

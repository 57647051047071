<div class="relative">
    <div class="fixed top-0 mt-16 right-0 z-40 pt-5 pr-8">
        <button color="primary" mat-raised-button *ngIf="fireService.user$ | async as user"
            (click)="onSave()">Speichern</button>
    </div>
    <div class="relative z-20 font-poppins text-2xl font-semibold xs:pt-16 text-center pt-6">Gesund mit Hund</div>
    <section class="relative z-20 mt-5 lg:max-w-5xl lg:mx-auto md:mx-20 xs:mx-8 pb-20 ">
        <button mat-mini-fab [color]="toggleBesuchshundInput ? 'accent': 'primary'"
            *ngIf="fireService.user$ | async as user" (click)="toggleBesuchshundInput = !toggleBesuchshundInput">
        <mat-icon>edit</mat-icon>
    </button>
        <div class="bg-gray-400 p-px mx-auto w-64"></div>
        <div class="text-center max-w-5xl mx-auto mt-4 text-gray-700 tracking-wide text-md xs:text-lg font-medium leading-6 font-nunito xs:font-lato px-5">
            <ng-container *ngIf="toggleBesuchshundInput; else elseTemplateOne">
                <textarea  class="border flex items-center border-pink-600  w-full" id="besuchshund" name="besuchshund" rows="9"  [(ngModel)]="data$?.besuchshund.description" ></textarea>
            </ng-container>
            <ng-template #elseTemplateOne>
                <div style="white-space: pre-wrap" >
                    {{data$?.besuchshund.description}}</div>
            </ng-template>
            <!-- Pablo ist ein sogenannter Listenhund, ein American Staffordshire-Terrier und macht gerade eine Ausbildung zum Therapiebegleithund im Altenheim von Kronberg im Taunus. -->
        </div>
        <div class="bg-gray-400 p-px mx-auto w-64 mt-4"></div>

        <section class="mt-12 sm:flex justify-center gap-10">
            <div class="border p-5 flex-grow max-w-md">
                <h2>Besuchhundedienst </h2>
                <div class="sm:flex gap-2 h-64">
                    <figure class="w-full h-full">
                        <img class="w-full h-full rounded-md overflow-hidden shadow-one object-cover" src="../assets/images/hund_mit_rollstuhlfahrer.jpg" alt="">
                    </figure>
                    <figure class="w-full h-full">
                        <img class="w-full h-full rounded-md overflow-hidden shadow-one object-cover" src="../assets/images/pablo.jpg" alt="">
                    </figure>
                </div>
               
            </div>
            <div class="border p-5 flex-grow max-w-md">
                <h2>Individualtraining mit Hund</h2>
                <img class="" src="../assets/images/team_hund.jpg" alt="">
                <div class="mt-5 text-gray-800 font-medium tracking-wide leading-5 mt-4 text-left xs:font-lato font-nunito text-center text-base sm:text-left">Trotz meiner anfänglichen Skepsis gegenüber Hunden hat sich die Zusammenarbeit mit der Martin Rütter Hundeschule als eine wundervolle Reise entwickelt, bei der ich und mein Hund Pablo zu einem starken Team wurden. Durch das Training und die positive Entwicklung habe ich mich schließlich zur Ausbildung als Martin Rütter Hundetrainerin entschieden, um andere Mensch-Hunde-Teams zu unterstützen und meine Arbeit als Gesundheitspädagogin zu erweitern.
                <br>
                <br>
                Für mehr Informationen besuchen Sie gerne die <a class="text-yellow-800 underline " href="https://www.martinruetter.com/wiesbaden-main-taunus-kreis/"> Martin Rütter Hundeschule </a>
                </div>
               
            </div>
        </section>
        
        <section class="sm:flex mt-12 max-w-5xl mx-auto">
            <div class="h-64 flex-grow-10 w-full sm:p-5 p-2">
                <figure class="w-full h-full">
                    <img class="w-full h-full rounded-md overflow-hidden shadow-one object-cover" src="../assets/images/pablo_auf_decke_1_r.jpg" alt="">
                </figure>
            </div>
            <div class="h-64 flex-grow-10 w-full sm:p-5 p-2">
                <figure class="w-full h-full">
                    <img class="w-full h-full rounded-md overflow-hidden shadow-one object-cover" src="../assets/images/pablo_auf_sessel_1_r.jpg" alt="">
                </figure>
            </div>
            <div class="h-64 flex-grow-10 w-full sm:p-5 p-2">
                <figure class="w-full h-full">
                    <img class="w-full h-full rounded-md overflow-hidden shadow-one object-cover" src="../assets/images/pablo_martin_rütter_1_r.jpg" alt="">
                </figure>
            </div>
        </section>
    </section>
</div>  
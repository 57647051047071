import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/service/firebase.service';
// import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  token;
  email = null; 
  loading: boolean = false;
  success: boolean = true;
  reason: string;
  response: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private fireService: FirebaseService
    ) {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.token = params.get('token');
      this.unsubscribe();
    });
  }


  async unsubscribe() {
    this.loading = true;
    let res = await this.fireService.unsubscribe(this.token, ''); 
    this.reason = ''; 
    if (res.success) {
      this.response = true;
      this.loading = false;
      this.success = true;
    } else {
      this.response = true;
      this.loading = false;
      this.success = false;
    }
  }

  ngOnInit(): void {
  }

}

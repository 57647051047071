import { Injectable, Component, Inject } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

export interface User {
  uid: string,
  displayName: string,
  email: string,
  photoURL?: string,
  emailVerified?: string,
  customData?: string,
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private db = firebase.firestore();
  private functions = firebase.app().functions('europe-west2');

  private _user = new BehaviorSubject<any>(null);
  private _reviews = new BehaviorSubject<any>(null);
  private _homepage = new BehaviorSubject<any>(null);
  private _prices = new BehaviorSubject<any>(null);
  private _other = new BehaviorSubject<any>(null);
  private _codes = new BehaviorSubject<any>(null);
  private _subscribers = new BehaviorSubject<any>([]);
  public readonly user$: Observable<any> = this._user.asObservable();
  public readonly reviews$: Observable<any> = this._reviews.asObservable();
  public readonly homepage$: Observable<any> = this._homepage.asObservable();
  public readonly prices$: Observable<any> = this._prices.asObservable();
  public readonly other$: Observable<any> = this._other.asObservable();
  public readonly codes$: Observable<any> = this._codes.asObservable();
  public readonly subscribers$: Observable<any> = this._subscribers.asObservable();


  constructor(
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
    firebase.auth().onAuthStateChanged(user => {
      // console.log('Auth called');
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          // console.log('Is Moderator: ', idTokenResult.claims.moderator)
        })
        this.setUser(user)
      }
      else this.setUser(null);
    })
  }

  private setUser(value) {
    this._user.next(value);
  }
  private setReviews(value) {
    this._reviews.next(value);
  }
  private setHomepageData(value) {
    this._homepage.next(value);
  }
  private setPricesData(value) {
    this._prices.next(value);
  }
  private setOtherData(value) {
    this._other.next(value);
  }
  private setCodes(value) {
    this._codes.next(value);
  }
  private setSubscribers(value) {
    this._subscribers.next(value);
  }


  signIn(email: string, password: string) {
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => {
          this.openSnackBar('Erfolgreich Eingelogged 🔓', '', 'bg-green-500');
          this.router.navigate(['/account']);
          resolve();
        })
        .catch(err => {
          this.openSnackBar('Falsche Eingabe 🔒', 'Überprüfen Sie Nutzername und/oder Passwort', 'bg-red-500')
          reject();
        })
    })
  }

  signOut() {
    firebase.auth().signOut();
  }

  loadReviews() {
    // console.log('Loading Reviews')
    this.db.collection('reviews').where("public", "==", true).get().then(querySnapshot => {
      let data = [];
      querySnapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() })
      })
      this.setReviews(data);
    })
  }

  loadAllReviews() {
    // console.log('Loading All Reviews')
    this.db.collection('reviews').get().then(querySnapshot => {
      let data = [];
      querySnapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() })
      })
      this.setReviews(data);
    })
  }

  updateReview(id: string, show: boolean) {
    // console.log('Updating Reviews')
    this.db.collection('reviews').doc(id).update({ public: show }).then(() => this.loadAllReviews())
  }

  getSubscribers() {
    // console.log('Loading Subscribers')
    this.db.collection('newsletter').doc('emails').get().then(doc => {
      this.setSubscribers(doc.data().emails)
    })
  }

  loadHomepageData() {
    // console.log('Loading Homepage data')
    this.db.collection('homepage').doc('home').get().then(doc => {
      let data = doc.data(); 
      data.offerList = [data.offerList[0], data.offerList[1],data.offerList[5],data.offerList[4],data.offerList[3],data.offerList[2]]
      this.setHomepageData(data);
    })
  }
  loadPricesData() {
    // console.log('Loading Prices data')
    this.db.collection('homepage').doc('prices').get().then(doc => {
      let data = doc.data(); 
      this.setPricesData(data);
    })
  }
  loadOtherData() {
    // console.log('Loading Other data')
    this.db.collection('homepage').doc('other').get().then(doc => {
      console.log(doc.data)
      let data = doc.data(); 
      this.setOtherData(data);
    })
  }

  getCodes() {
    // console.log('Loading Codes')
    this.db.collection('codes').get().then(querySnapshot => {
      let data = [];
      querySnapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() })
      })
      this.setCodes(data);
    })
  }

  addCode(code: string, name: string) {
    this.db.collection('codes').doc(code).set({
      code, for: name,
      createdAt: Date.now().toLocaleString(),
      used: false
    }).then(() => {
      this.getCodes();
      this.openSnackBar("Code Gespeichert", "", 'bg-green-500');
    }).catch(err => this.openSnackBar(err.code, err.message, 'bg-red-500'));
    return;
  }

  async updateHomepageData(data: any) {
    this.db.collection('homepage').doc('home').update(data).then(() => {
      this.openSnackBar('Daten erfolgreich Geuppdated 💾', '', 'bg-green-500')
      this.loadHomepageData();
    }).catch(err => {
      this.openSnackBar('Fehlende Bereichtigung', err.message, 'bg-red-500')
    })
  }
  async updatePricesData(data: any) {
    this.db.collection('homepage').doc('prices').update(data).then(() => {
      this.openSnackBar('Daten erfolgreich Geuppdated 💾', '', 'bg-green-500')
      this.loadPricesData();
    }).catch(err => {
      this.openSnackBar('Fehlende Bereichtigung', err.message, 'bg-red-500')
    })
  }
  async updateOtherData(data: any) {
    this.db.collection('homepage').doc('other').update(data).then(() => {
      this.openSnackBar('Daten erfolgreich Geuppdated 💾', '', 'bg-green-500')
      this.loadOtherData();
    }).catch(err => {
      this.openSnackBar('Fehlende Bereichtigung', err.message, 'bg-red-500')
    })
  }

  // Firebase Functions

  async unsubscribe(token: string, reason: string) {
    let unsubscribe = this.functions.httpsCallable('testunsubscribe');
    let result = await unsubscribe({ token, reason });
    return result.data;
  }

  async postReview(code: number, header: string, footer: string, main: string, image: any) {
    let unsubscribe = this.functions.httpsCallable('reviewForm');
    let result = await unsubscribe({ code, header, footer, main, image });
    if (result.data.success) this.openSnackBar('Rezension erfolgreich hochgeladen!', 'Sie wird in kürze auf der Homepage veröffentlicht.', 'bg-green-500');
    if (result.data.info) this.openSnackBar(result.data.info, '', 'bg-blue-500');
    return;
  }

  async requestEmailListDeletion(email: string) {
    let requestEmailDeletion = this.functions.httpsCallable('requestEmailListDeletion');
    let result = await requestEmailDeletion({ email });
    if (result.data.success) this.openSnackBar('Abmeldung begonnen!', result.data.success, 'bg-green-500');
    if (result.data.error) this.openSnackBar(result.data.error, '', 'bg-red-500', 10000);
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 10000);
  }

  async postContact(formData: any) {
    let unsubscribe = this.functions.httpsCallable('contactForm');
    let result = await unsubscribe({ ...formData });
    if (result.data.success) this.openSnackBar('Nachricht erfolgreich versendet!', 'Sie erhalten in kürze eine Bestätigung per Email.', 'bg-green-500');
    if (result.data.info) this.openSnackBar('Überprüfen Sie ihre eingabe.', 'Die mit einem Stern markierten Felder sind Pflichtfelder.', 'bg-blue-500');
    return;
  }

  async postEmail(layout, receivers, subject, textarea_one, textarea_two, textarea_three, urls, textarea_four, test) {
    let email = this.functions.httpsCallable('email');
    let result = await email(
      { layout, receivers, subject, textarea_one, textarea_two, textarea_three, urls, textarea_four, test }
    );
    // console.log(result)
    if (result.data.success) this.openSnackBar(result.data.success, '', 'bg-green-500');
    if (result.data.error) this.openSnackBar('', result.data.error, 'bg-red-500');
    return;
  }

  async postNewsletterConfirmation(token: string) {
    let unsubscribe = this.functions.httpsCallable('testconfirmNewsletter');
    let result = await unsubscribe({ token: token });
    // console.log(result);
    return result.data;
  }

  async postAppointment(email: string, name: string, from: string, to: string, offer: string, date: string) {
    let unsubscribe = this.functions.httpsCallable('appointmentForm');
    let result = await unsubscribe({ email, name, from, to, offer, date });
    if (result.data.success) {
      this.openSnackBar('Anfrage wurde gesendet.', 'Sie erhalten in kürze eine Bestätigungsmail.', 'bg-green-500');
      return { success: '' }
    }
    if (result.data.info) {
      this.openSnackBar('Überprüfen Sie ihre eingabe.', 'Die mit einem Stern markierten Felder sind Pflichtfelder.', 'bg-blue-500');
      return { info: '' }
    }
  }

  async subscribeToNewsletter(email: string) {
    let sub = this.functions.httpsCallable('testnewsletter');
    let result = await sub({ email });
    // console.log(result);
    if (result.data.info) this.openSnackBar('Diese Email existiert beriets', '', 'bg-red-500');
    if (result.data.success) this.openSnackBar('Newsletter abonniert! 🎉', 'Sie erhalten in kürze einen Bestätigungslink.', 'bg-green-500');
    return;
  }


  // Snackbar
  openSnackBar(title: string, text: string, color: string, duration?: number) {
    this._snackBar.openFromComponent(NewsletterSnackBarComponent, {
      duration: duration | 7000,
      data: { title, text },
      panelClass: [color],
      verticalPosition: 'bottom'
    });
  }
}


@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      {{data.title}} <br/>
      <div class="mt-2 font-lato font-normal text-base text-grey-800">{{data.text}}</div>
    </div>
  `
})

export class NewsletterSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}


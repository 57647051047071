import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDialogComponent } from 'src/app/components/dialogs/appointment-dialog/appointment-dialog.component';


@Component({
  selector: 'app-relaxation-card',
  templateUrl: './relaxation-card.component.html',
  styleUrls: ['./relaxation-card.component.scss']
})
export class RelaxationCardComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  @Input() offer;

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AppointmentDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
    });
  }

}

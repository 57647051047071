<div class="pb-12">
    <section class="relative">
        <figure class="w-full">
            <img class="w-full h-64 sm:h-72 object-cover shadow-two rounded-sm overflow-hidden" [src]="offer.src" alt="">
            <div class="absolute inset-x-0 mt-auto bottom-0 w-full text-center pb-6 font-nunito font-bold text-xl sm:text-2xl md:text-3xl">
                <div class="mb-2 text-white">{{offer.name}}</div>
                <div class="flex justify-center mx-5 sm:mx-0">
                    <svg width="565" height="34" viewBox="0 0 565 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="white"
                            d="M0.992188 15.5L236.992 14.5M328.992 15.5L564.992 14.5M297.999 17C297.999 25.2843 291.283 32 282.999 32C274.714 32 267.999 25.2843 267.999 17C267.999 8.71573 274.714 2 282.999 2C291.283 2 297.999 8.71573 297.999 17Z"
                            stroke="white" stroke-width="3" />
                    </svg>
                </div>
            </div>
        </figure>
    </section>
    <section class="mt-12">
        <div class="md:grid md:grid-cols-6 flex flex-col-reverse">
            <div class="col-span-2 flex items-center flex-col p-5 w-84 mx-auto md:mx-0 md:w-full">
                <figure>
                    <img class="object-cover w-20 h-20" [src]="offer.watch" alt="">
                </figure>
                <div class="flex justify-between w-full mt-10">
                    <div *ngFor="let condition of offer.condition" class="flex-grow-10 text-center mx-10">
                        <div class="mb-2 text-green-500 font-nunito font-semibold text-lg tracking-wider">
                            {{condition.time}}
                        </div>
                        <div [ngClass]="{'mx-10': offer.condition.length <= 1}"
                            class="bg-green-500 p-px rounded-md overflow-hidden "></div>
                        <div class="mt-2 text-green-500 font-nunito font-semibold text-lg">
                            {{condition.price}}
                        </div>
                    </div>
                </div>
            </div>
            <div class=" p-5 col-span-4 font-nunito text-lg tracking-wide leading-7 text-gray-700">
                {{offer.description}}
            </div>
        </div>
    </section>
    <section class="my-16 flex justify-center">
        <button mat-stroked-button class="bg-white w-64 hover:shadow-three text-gray-700 text-xl border-2 text-center"
            style="border-color: #32b72f; padding:6px; border-radius: 2px;" (click)="openDialog()">
            <span class="mr-2">Termin vereinbaren</span>
            <mat-icon>
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="#32B72F"
                        d="M19,3H18V1H16V3H8V1H6V3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H10V19H5V8H19V9H21V5A2,2 0 0,0 19,3M21.7,13.35L20.7,14.35L18.65,12.35L19.65,11.35C19.85,11.14 20.19,11.13 20.42,11.35L21.7,12.63C21.89,12.83 21.89,13.15 21.7,13.35M12,18.94L18.07,12.88L20.12,14.88L14.06,21H12V18.94Z" />
                </svg>
            </mat-icon>
        </button>
    </section>
    <section *ngIf="offer.images.length > 1" class="sm:flex mt-12">
        <div *ngFor="let image of offer.images" class="h-64 flex-grow-10 w-full sm:p-5 p-2">
            <figure class="w-full h-full">
                <img class="w-full h-full rounded-md overflow-hidden shadow-one object-cover" [src]="image.src" alt="">
            </figure>
        </div>
    </section>
</div>
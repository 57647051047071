<section class="overflow-x-hidden relative">
    <div class="fixed top-0 mt-16 right-0 z-40 pt-5 pr-8">
        <button color="primary" mat-raised-button *ngIf="fireService.user$ | async as user"
            (click)="onSave()">Speichern</button>
    </div>
    <figure class="hidden md:block h-full absolute left-0 top-0 z-0 md:z-10 lg:-mt-10 lg2:-mt-32 xl:-mt-48 ">
        <img src="../assets/images/leaf-original-removebg.avif" alt="">
    </figure>
    <section class="px-5 md:px-8 font-poppins font-semibold relative z-10 text-center sm:mt-0 mt-6">
        <div class="md:mr-auto md:ml-0 md:max-w-xl md:text-left md:pl-40">
            <div class="text-gray-500 font-semibold text-sm sm:mt-2">staatlich geprüfte</div>
            <div class="text-gray-500 font-semibold text-base">Gesundheitsberaterin & Gesundheitspädagogin</div>
        </div>

        <div
            class="text-green-500 text-3xl lg:text-4xl leading-7 md:leading-9 tracking-wide mt-5 xs:mt-8 mx-auto max-w-lg lg:max-w-xl lg:leading-10 md:ml-auto md:mr-0 md:text-left relative">
            <div class="absolute top-0 left-0 z-50 -mt-5 -ml-5">
                <button mat-mini-fab [color]="toggleTitle ? 'accent': 'primary'" *ngIf="fireService.user$ | async as user"
                    (click)="toggleTitle = !toggleTitle">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
            <div>
                <ng-container *ngIf="toggleTitle; else titleTemplate">
                    <textarea class=" lg:mt-1 border border-pink-600  h-full w-full relative z-40"
                        [(ngModel)]="data$.title"></textarea>
                </ng-container>
                <ng-template #titleTemplate>
                    {{data$?.title}}
                </ng-template>
            </div>
        </div>
        <div
            class="text-gray-600 text-lg px-5 mt-5 xs:mt-8 lg:mt-10 lg:text-xl lg:mr-20 leading-6 max-w-md mx-auto md:ml-auto md:mr-0 md:text-left relative">
            <div class="absolute top-0 left-0 z-50 -mt-5 -ml-5">
                <button mat-mini-fab [color]="toggleSubtitle ? 'accent': 'primary'" *ngIf="fireService.user$ | async as user"
                    (click)="toggleSubtitle = !toggleSubtitle">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="toggleSubtitle; else subtitleTemplate">
                <textarea class=" lg:mt-1 border border-pink-600  h-full w-full relative z-40"
                    [(ngModel)]="data$.subtitle"></textarea>
            </ng-container>
            <ng-template #subtitleTemplate>
                {{data$?.subtitle}}
            </ng-template>
        </div>
    </section>
    <section class="relative z-10 px-5 md:px-10 mt-10 lg:mt-64 md:mt-32 lg2:max-w-9xl lg2:mx-auto">
        <div class="hidden md:block font-marker text-gray-700 text-3xl pb-6 tracking-wider">Mein Angebot&nbsp;<span
                class="text-green-500">!</span></div>
        <div class="relative z-10 flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 sm:gap-5">
            <div class="mb-5 w-full mx-auto max-w-md h-40 lg:h-48 bg-white flex rounded-lg overflow-hidden shadow-three offer relative cursor-pointer hover:shadow-ten transition-short relative"
                *ngFor="let offer of data$?.offerList; let i = index">
                <div class="absolute top-0 right-0 z-50">
                    <button mat-mini-fab [color]="toggleOfferCardInput[i] ? 'accent': 'primary'"
                        *ngIf="fireService.user$ | async as user" (click)="toggleOfferCardInput[i] = !toggleOfferCardInput[i]">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <a [routerLink]="'/' + offer.route" class="absolute inset-0 opacity-0 cursor-pointer z-40">
                </a>
                <figure class="h-full w-5/12 overflow-hidden">
                    <img class="object-cover w-full h-full" [src]="offer.url" alt="offer.title">
                </figure>
                <div class="h-full w-7/12 p-3 lg:p-5 relative overflow-hidden">
                    <ng-container *ngIf="toggleOfferCardInput[i]; else elseTemplate">
                        <input type="text" name="" [(ngModel)]="data$?.offerList[i].title"
                            class="border flex items-center w-full border-pink-600 relative text-lg lg:text-xl tracking-wide text-gray-700 pb-2 font-semibold z-40">
                        <textarea style="height:80%"
                            class="text-sm lg:mt-1 border border-pink-600  text-gray-600 leading-5 lg:text-base h-full w-full relative z-40"
                            [(ngModel)]="data$?.offerList[i].text"></textarea>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="text-lg lg:text-xl tracking-wide text-gray-700 pb-2 font-semibold">{{offer.title}}
                        </div>
                        <div class="text-sm lg:mt-1 text-gray-600 leading-5 lg:text-base">{{offer.text}}</div>
                    </ng-template>
                </div>
            </div>
        </div>

    </section>
    <section class="relative px-5 mt-6 pt-12 ">
        <div class="lg2:max-w-9xl lg2:mx-auto">
            <div class="bg-white absolute inset-0 z-0"></div>
            <div class="w-full max-w-2xl font-lato relative p-5 mr-auto my-auto md:pl-24 lg:pl-40">
                <div class="absolute top-0 right-0 z-50">
                    <button mat-mini-fab [color]="toggleMottoTitle ? 'accent': 'primary'"
                        *ngIf="fireService.user$ | async as user" (click)="toggleMottoTitle = !toggleMottoTitle">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div class="absolute inset-0 w-full h-full bg-white z-10 opacity-75"></div>
                <div class="text-2xl font-semibold text-gray-800 relative z-20 leading-6">
                    <ng-container *ngIf="toggleMottoTitle; else mottoTitleTemplate">
                        <textarea class=" lg:mt-1 border border-pink-600  h-full w-full relative z-40"
                            [(ngModel)]="data$.mottotitle"></textarea>
                    </ng-container>
                    <ng-template #mottoTitleTemplate>
                        {{data$?.mottotitle}}<span class="text-green-500">!</span>
                    </ng-template>

                </div>
                <div class="pt-8 text-gray-800 text-lg font-bold tracking-wide leading-normal relative z-20">
                    <div class="absolute top-0 right-0 z-50">
                        <button mat-mini-fab [color]="toggleMottoText ? 'accent': 'primary'"
                            *ngIf="fireService.user$ | async as user" (click)="toggleMottoText = !toggleMottoText">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <ng-container *ngIf="toggleMottoText; else mottoSubtitleTemplate">
                        <textarea class=" lg:mt-1 border border-pink-600  h-full w-full relative z-40" style="min-height: 130px;"
                            [(ngModel)]="data$.mottotext"></textarea>
                    </ng-container>
                    <ng-template #mottoSubtitleTemplate>
                        {{data$?.mottotext}}<span class="text-green-500">!</span>
                    </ng-template>
                </div>
                <div class="mt-5 font-marker text-green-500 text-lg relative z-20">Mein Motto</div>
                <div class="flex items-center relative z-20 mt-5">
                    <div class="relative">
                        <button class="absolute right-0 top-0 z-20" matTooltipPosition="after" matTooltipClass="text-base"
                            matTooltip="Ihre Email-Adresse wird nicht and Dritte weitergegeben. Sie können die Einwilligung jederzeit wiederufen.">
                            <mat-icon>
                                <svg class="text-gray-400" style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                </svg>
                            </mat-icon>
                        </button>
                        <form [formGroup]="newsletterForm" #newsletterFormDirective="ngForm"
                            (ngSubmit)="onSubmitNewsletter(newsletterFormDirective)">
                            <div class="w-72 pt-3">
                                <div class="absolute top-0 inset-x-0 w-full mr-5">
                                    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                                </div>
                                <mat-form-field class="w-full pb-0 mb-0">
                                    <mat-label>Kostenlosen Newsletter abonnieren</mat-label>
                                    <input autocomplete="off" #email formControlName="email" matInput type="email"
                                        [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="email.invalid">Überprüfen Sie Ihre Email-Adresse</mat-error>
                                </mat-form-field>
                            </div>
                            <button type="submit" mat-raised-button
                                class="text-white bg-green-500 mt-1 pt-0 w-full">Abonnieren !</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="ml-auto -mr-24 max-w-xl md:max-w-2xl relative z-0 relative">
                <figure class="w-full h-full">
                    <img class="object-cover" src="../assets/images/tree.png" alt="">
                </figure>
            </div>
        </div>
    </section>
    <section class="relative z-10 py-10 pt-16 lg:flex lg:flex-col lg:justify-center "
        style="background-color: #f1f1f1;">
        <div class="md:max-w-4xl md:mx-auto">
            <div class="text-2xl text-green-500 font-semibold text-center font-poppins">Rezensionen</div>
            <div class="flex overflow-x-scroll mt-8 pl-5 lg:mx-auto">
                <div class="bg-white mr-5 p-3 rounded-sm shadow-one m-2 overflow-hidden w-full h-108"
                    style="min-width: 250px; max-width: 250px;" *ngFor="let item of fireService.reviews$ | async">
                    <figure class="w-24 h-24 mx-auto rounded-full overflow-hidden">
                        <img class="w-full h-full object-cover" [src]="item.url" alt="Gesicht">
                    </figure>
                    <div class="p-px bg-green-500 mx-5 rounded-full mt-2"></div>
                    <div class="text-gray-700 text-center font-lato mt-5 px-5">
                        <div class="font-medium text-lg"> {{item.header}} </div>
                        <div [title]="item.main" class="mt-3 ellipsis"> {{item.main}} </div>
                        <div class="mt-4"> {{item.footer}} </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-8">
                <button mat-raised-button style="border: 2px solid #32B72F" class="font-poppins font-semibold"
                    (click)="openDialog()">Rezension schreiben<mat-icon class="ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path fill="#32B72F"
                                d="M7 20c-.55 0-1-.45-1-1v-3H2a2 2 0 01-2-2V2a2 2 0 012-2h16a2 2 0 012 2v12c0 1.11-.89 2-2 2h-6.1l-3.7 3.71c-.2.19-.45.29-.7.29H7zm1-6v3.08L11.08 14H18V2H2v12h6zm5.84-7.8l-1.01 1.01-2.07-2.03 1.01-1.02c.2-.21.54-.22.78 0l1.29 1.25c.21.21.22.55 0 .79zM6 9.91l4.17-4.19 2.07 2.08L8.08 12H6V9.91z" />
                        </svg>
                    </mat-icon>
                </button>
            </div>
        </div>
    </section>
    <section class="relative z-10 bg-white py-12 md:px-12">
        <div lg2:max-w-9xl lg2:mx-auto>
            <div
                class="text-xl md:text-2xl font-poppins text-gray-800 tracking-wide font-semibold text-center md:text-left">
                Kooperationspartner</div>
            <div class="mt-8 md:mt-20 lg:flex lg:justify-center">
                <div class="overflow-hidden shadow-one mx-auto bg-white relative rounded-md w-72 xs:w-84 m-2 mb-5 xs:mb-10 md:h-72 md:flex md:w-152"
                    *ngFor="let item of data$?.cooperationList; let i = index">
                    <div class="absolute top-0 right-0 z-50">
                        <button mat-mini-fab [color]="toggleCooperationInput[i] ? 'accent': 'primary'"
                            *ngIf="fireService.user$ | async as user"
                            (click)="toggleCooperationInput[i] = !toggleCooperationInput[i]">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <figure class="w-full h-40 md:h-full md:w-1/2 overflow-hidden">
                        <img class="object-cover w-full h-full" [src]="item.url" alt="item.title">
                    </figure>
                    <div
                        class="p-5 md:p-5 border border-gray-200 md:w-1/2 border-b-0 border-r-0 border-l-0 md:flex md:flex-col md:justify-between">
                        <div class="relative h-full">
                            <ng-container *ngIf="toggleCooperationInput[i]; else elseTemplate">
                                <input type="text" name="" [(ngModel)]="data$?.cooperationList[i].title"
                                    class="border flex items-center w-full border-pink-600 relative text-lg lg:text-xl tracking-wide text-gray-700 pb-2 font-semibold z-40">
                                <textarea style="height:80%"
                                    class="text-sm lg:mt-1 border border-pink-600  text-gray-600 leading-5 lg:text-base h-full w-full relative z-40"
                                    [(ngModel)]="data$?.cooperationList[i].text"></textarea>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <div class="font-poppins text-gray-700 text-lg font-semibold"> {{item.title}} </div>
                                <div class="font-lato text-gray-600 text-base mt-4"> {{item.text}} </div>
                            </ng-template>
                        </div>
                        <a [href]="item.link">
                            <div
                                class="border-green-500 text-green-500 font-poppins font-semibold border-2 mt-5 shadow-one cursor-pointer hover:shadow-four transition-short hover:bg-green-500 hover:text-white text-center py-3 text-md tracking-wide rounded-lg overflow-hidden">
                                Zum Shop
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<div class="w-full max-w-5xl overflow-hidden mx-auto pb-32">
    <figure class="relative w-full h-96 mt-12 shadow-one rounded-md overflow-hidden">
        <img class="w-full h-full object-cover" src="../../assets/images/jogging.jpg" alt="">
        <div class="absolute inset-0 w-full h-full opacity-50 bg-white"></div>
        <div class="absolute inset-x-0 top-0 mt-4 text-center w-full">
            <div class="relative z-20 font-poppins text-xl xs:text-2xl font-semibold pt-5 text-center">Betriebliche
                Gesundheitsförderung<span class="text-green-500">.</span>
            </div>
            <div class="font-lato text-lg text-gray-900 mt-5">Mit gesunden Mitarbeitern zum Erfolg</div>
        </div>
    </figure>
    <div class="relative mx-5 xs:mx-10 sm:mx-20 font-nunito text-lg font-medium sm:text-xl leading-7 sm:font-semibold mt-6 sm:text-left text-center">
        Aktive Unternehmen brauchen aktive Arbeitnehmer. Um den hohen Anforderungen in Beruf und Privatleben auf Dauer
        gerecht zu werden ist es wichtig, Mitarbeiter
        adäquat zu unterstützen. Dies muss immer auf die Belange der jeweiligen Unternehmen und ihrer Mitarbeiter
        abgestimmt sein.
        Als Gesundheitsbeauftragte für Betriebliches Eingliederungsmanagement und Betriebliche Gesundheitsförderung unterstütze ich Sie bei der Ermittlung, Planung und Durchführung der für Sie passenden Maßnahmen.
    </div>
    <section class="my-16 flex justify-center">
        <a mat-stroked-button class="bg-white w-64 hover:shadow-three text-gray-700 text-xl border-2 text-center"
            style="border-color: #32b72f; padding:6px; border-radius: 2px;" href="https://calendar.app.google/6riBcYwqvtGyvKBs8">
            <span class="mr-2">Termin anfragen</span>
            <mat-icon>
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="#32B72F" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                </svg>
            </mat-icon>
        </a>
    </section>
</div>
import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/service/firebase.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  data$: any;
  
  constructor(
    public dialog: MatDialog,
    public fireService: FirebaseService,
  ) { } 
    
    ngOnInit(): void {
      this.fireService.loadOtherData();
      this.fireService.other$.subscribe(data => {
        this.data$ = data;
      })
    window.scroll(0, 0);
  }
    
    toggleBesuchshundInput: boolean = false;

    async onSave() {
      this.fireService.updateOtherData(this.data$);
    }

  

}

<section class="pb-32 relative">
    <div class="fixed top-0 mt-16 right-0 z-40 pt-5 pr-8">
        <button color="primary" mat-raised-button *ngIf="fireService.user$ | async as user"
            (click)="onSave()">Speichern</button>
    </div>
    <div class="relative z-20 font-poppins text-2xl font-semibold pt-16 text-center">Preisgestaltung<span
            class="text-green-500">.</span>
    </div>
    <section class="md:mx-20 mx-5 xs:mx-24 lg:max-w-5xl lg:mx-auto mt-10 relative z-20 font-lato text-xl font-medium text-gray-700">
        <div class="text-gray-900 text-lg font-semibold pb-6">Gruppenangebote</div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            
            <div *ngFor="let offer of data$?.group; let i = index" class="h-40 relative  border-2 border-green-500 flex flex-col justify-between rounded-sm overflow-hidden cursor-pointer hover:shadow-five trans" style="background-color:rgba(255,255,255,0.85);">
                <div class="absolute top-0 right-0 z-50">
                    <button mat-mini-fab [color]="toggleGroupCardInput[i] ? 'accent': 'primary'"
                        *ngIf="fireService.user$ | async as user" (click)="toggleGroupCardInput[i] = !toggleGroupCardInput[i]">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div>
                    <ng-container *ngIf="toggleGroupCardInput[i]; else elseTemplateOne">
                        <input type="text" name="" [(ngModel)]="data$?.group[i].name"
                            class="border flex items-center w-full border-pink-600 relative font-semibold text-gray-900 p-5 pb-3 text-md z-40">
                        <input type="text" name="" [(ngModel)]="data$?.group[i].info"
                            class="border flex items-center w-full border-pink-600 relative px-5 font-gray-700 text-md z-40">
                    </ng-container>
                    <ng-template #elseTemplateOne>
                        <div class="font-semibold text-gray-900 p-5 pb-3 text-md">
                            {{offer.name}}
                        </div>
                        <div class="px-5 font-gray-700 text-md">{{offer.info}}</div>
                    </ng-template>
                   
                </div>
                <div class="font-bold text-green-500 text-xl p-5 flex justify-end">
                    <div>
                        <span class=" mr-2" >€</span> 
                        <ng-container *ngIf="toggleGroupCardInput[i]; else elseTemplateTwo">
                            <input type="text" name="" [(ngModel)]="data$?.group[i].price"
                            class="border items-center border-pink-600 relative  z-40">
                        </ng-container>
                        <ng-template #elseTemplateTwo>
                            {{offer.price}}
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-gray-900 text-lg font-semibold pb-6 mt-12">Einzelstunden</div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            <div class="sm:col-span-2 grid sm:grid-cols-2 gap-5">
                
                <div *ngFor="let offer of data$?.individual; let i = index" class="h-40 relative  border-2 border-green-500 flex flex-col justify-between rounded-sm overflow-hidden cursor-pointer hover:shadow-five trans" style="background-color:rgba(255,255,255,0.85);">
                    <div class="absolute top-0 right-0 z-50">
                        <button mat-mini-fab [color]="toggleIndividualCardInput[i] ? 'accent': 'primary'"
                            *ngIf="fireService.user$ | async as user" (click)="toggleIndividualCardInput[i] = !toggleIndividualCardInput[i]">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div>
                        <ng-container *ngIf="toggleIndividualCardInput[i]; else elseTemplateThree">
                            <input type="text" name="" [(ngModel)]="data$?.individual[i].name"
                                class="border flex items-center w-full border-pink-600 relative font-semibold text-gray-900 p-5 pb-3 text-md z-40">
                            <input type="text" name="" [(ngModel)]="data$?.individual[i].info"
                                class="border flex items-center w-full border-pink-600 relative px-5 font-gray-700 text-md z-40">
                        </ng-container>
                        <ng-template #elseTemplateThree>
                            <div class="font-semibold text-gray-900 p-5 pb-3 text-md">
                                {{offer.name}}
                            </div>
                            <div class="px-5 font-gray-700 text-md">{{offer.info}}</div>
                        </ng-template>
                       
                    </div>
                    <div class="font-bold text-green-500 text-xl p-5 flex justify-end">
                        <div>
                            <span class=" mr-2" >€</span> 
                            <ng-container *ngIf="toggleIndividualCardInput[i]; else elseTemplateFour">
                                <input type="text" name="" [(ngModel)]="data$?.individual[i].price"
                                class="border items-center border-pink-600 relative  z-40">
                            </ng-container>
                            <ng-template #elseTemplateFour>
                                {{offer.price}}
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         
        <!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Angebot </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
          
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef> Art des Angebotes </th>
              <td mat-cell *matCellDef="let element"> <mat-chip-list aria-label="Fish selection">
                <mat-chip [ngStyle]="{'background-color': element.tags[0].color}" selected>{{element.tags[0].type}}</mat-chip>
            </mat-chip-list></td>
            </ng-container>
          
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef> Einheit </th>
              <td mat-cell *matCellDef="let element"> {{element.time}} </td>
            </ng-container>
          
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef> Preis </th>
              <td mat-cell *matCellDef="let element"> {{element.price}} Euro</td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> -->
        <!-- <div *ngFor="let price of pricingList" class="flex">
            <mat-chip-list aria-label="Fish selection">
                <mat-chip *ngFor="let tag of price.tags" [ngStyle]="{'background-color': tag.color}" selected>{{tag.type}}</mat-chip>
            </mat-chip-list>
        </div> -->
    </section>
    <section class="fixed inset-0 z-10">
        <figure class="w-full h-full">
            <img class="w-full h-full object-cover lg:mt-16" src="../assets/images/price.jpg" alt="">
        </figure>
        <div class="fixed inset-0 z-10 bg-white opacity-50"></div>
    </section>
    <!-- <figure class="hidden md:block h-full absolute left-0 top-0 z-0 md:z-10 lg:-mt-10 lg2:-mt-32 xl:-mt-48 ">
        <img src="../assets/images/leaf-original-removebg.png" alt="">
    </figure> -->
    <!-- <section class="px-5 md:px-8 font-poppins font-semibold relative z-10 text-center">
        <div class="md:mr-auto md:ml-0 md:max-w-md md:text-left">
            <div class="text-gray-500 font-semibold text-sm sm:mt-2 pt-2">staatlich geprüfte</div>
            <div class="text-gray-500 font-semibold text-base">Gesundheitsberaterin & Gesundheitspädagogin</div>
        </div>
    </section>
    <section class="z-20 relative mt-5 p-5 md:px-8">
        <div class="font-semibold font-poppins text-3xl text-gray-800 text-center">Kontakt</div>
    </section>
    <section class="z-20 relative p-6 mt-16">
        <div class="bg-white absolute inset-0 opacity-75 z-10"></div>
        <div class="flex my-10 relative z-20">
            <section class="w-1/2 flex -mt-6">
                <div class="ml-10">
                    <div class="flex items-center">
                        <figure class="text-green-500 mr-2">
                            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="M17.25,18H6.75V4H17.25M14,21H10V20H14M16,1H8A3,3 0 0,0 5,4V20A3,3 0 0,0 8,23H16A3,3 0 0,0 19,20V4A3,3 0 0,0 16,1Z" />
                            </svg>
                        </figure>
                        <div class="text-gray-600 font-lato font-semibold tracking-wide text-lg">
                            01607047040
                        </div>
                    </div>
                    <div class="flex items-center mt-5">
                        <figure class="text-green-500 mr-2">
                            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="M19.07 13.88L13 19.94V22H15.06L21.12 15.93M22.7 13.58L21.42 12.3C21.32 12.19 21.18 12.13 21.04 12.13C20.89 12.14 20.75 12.19 20.65 12.3L19.65 13.3L21.7 15.3L22.7 14.3C22.89 14.1 22.89 13.78 22.7 13.58M11 18H4V8L12 13L20 8V10H22V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H11V18M20 6L12 11L4 6H20Z" />
                            </svg>
                        </figure>
                        <div class="text-gray-600 font-lato font-semibold tracking-wide text-lg">
                            <a class="font-base text-blue-600"
                                href="mailto:info@gesundheitsberatung-eschborn.de?subject=Anfrage&amp;body=">info@gesundheitsberatung-eschborn.de</a>
                        </div>
                    </div>
                    <div class="flex items-center mt-5">
                        <figure class="text-green-500 mr-2">
                            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                <path fill="currentColor"
                                    d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                            </svg>
                        </figure>
                        <div class="text-gray-600 font-lato font-semibold tracking-wide text-lg">
                            In den Weingärten 120, 65760 Eschborn
                        </div>
                    </div>

                </div>
            </section>
            <section class="w-1/2" style="opacity: 96%;">
                <iframe width="600" height="300" class="bg-gray-300"
                    src="http://maps.google.de/maps?hl=de&q=In+den+Weingärten+120,+65760+Eschborn&ie=UTF8&t=&z=14&iwloc=B&maptype=roadmap&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a
                        href="http://www.siteway.de/maps-generator/"
                        title="Google Maps Gesundheitsberatung Eschborn">(c) Homepage Google Maps Generator</a>
                </iframe>
            </section>
        </div>
    </section>
    <section class="relative z-20 mt-20 ">
        <div class="max-w-2xl mr-auto ml-10 -mt-72 bg-white w-full overflow-hidden shadow-two p-5 ">
            <div class="text-gray-700 font-poppins text-xl font-semibold mb-5">Kontaktformular</div>
            <form [formGroup]="contactForm" #contactFormDirective="ngForm"
                (ngSubmit)="onSubmitContactForm(contactFormDirective)">
                <div>
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Vorname, Nachname <span class="text-green-500">*</span></mat-label>
                        <input #name formControlName="name" matInput type="text" autocomplete="off"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Betreff <span class="text-green-500">*</span></mat-label>
                        <input #subject formControlName="subject" matInput type="text" autocomplete="off"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Firma</mat-label>
                        <input #company formControlName="company" matInput type="text" autocomplete="off"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Telefon/ Mobil</mat-label>
                        <input #phone formControlName="phone" matInput type="text" autocomplete="off"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Email <span class="text-green-500">*</span></mat-label>
                        <input #email formControlName="email" matInput type="email" autocomplete="off"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Datum</mat-label>
                        <input matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Nachricht <span class="text-green-500">*</span></mat-label>
                        <input #message formControlName="message" matInput type="" autocomplete="off"
                            [errorStateMatcher]="matcher">
                    </mat-form-field>
                </div>

            </form>
        </div>
    </section> -->
</section>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_KEY: 'AIzaSyBt_x35ox8QOqsOY_bwF204SgaezPjDJBQ',
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyBlLg4Uud1FiVm3rQM2kh8HNTlYxxhPUKw",
    authDomain: "gesundheitsberatung-eschborn.firebaseapp.com",
    databaseURL: "https://gesundheitsberatung-eschborn.firebaseio.com",
    projectId: "gesundheitsberatung-eschborn",
    storageBucket: "gesundheitsberatung-eschborn.appspot.com",
    messagingSenderId: "831222969763",
    appId: "1:831222969763:web:9fa5f16e09b40850339616",
    measurementId: "G-S4SEHSPSC1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<div class="w-full lg:max-w-5xl lg:mx-auto lg:px-0 sm:px-20 xs:px-8 px-5">
    <div class="relative z-20 font-poppins text-2xl font-semibold pt-16 text-center">Ferienhaus<span
            class="text-green-500">.</span>
    </div>
    <section class="mt-10">
        <div class="md:grid-cols-2 md:grid md:h-128 shadow-three rounded-md overflow-hidden bg-white">
            <figure class="w-full h-56 xs:h-72 md:h-full">
                <img class="w-full h-full object-cover" src="../../assets/images/ferienhaus.jpg" alt="">
            </figure>
            <div class="p-6">
                <div class="font-lato font-medium text-2xl text-gray-800 tracking-wide">Ferienhaus Beck</div>
                <div class="font-lato font-medium text-md mt-3 tracking-wide text-gray-700">Rustikales
                    Selbstversorgerhaus
                    für ruhige und entspannende Tage in idyllischer Lage.</div>
                <div class="flex items-center py-2 mt-6">
                    <div class="mr-2">
                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14 13.0001V15.0001H0V13.0001C0 13.0001 0 9.00005 7 9.00005C14 9.00005 14 13.0001 14 13.0001ZM10.5 3.50005C10.5 2.80782 10.2947 2.13113 9.91014 1.55556C9.52556 0.979985 8.97893 0.531381 8.33939 0.266474C7.69985 0.00156766 6.99612 -0.067744 6.31718 0.0673043C5.63825 0.202353 5.01461 0.535695 4.52513 1.02518C4.03564 1.51466 3.7023 2.1383 3.56725 2.81724C3.4322 3.49617 3.50152 4.1999 3.76642 4.83944C4.03133 5.47899 4.47993 6.02561 5.0555 6.4102C5.63108 6.79478 6.30777 7.00005 7 7.00005C7.92826 7.00005 8.8185 6.6313 9.47487 5.97493C10.1313 5.31855 10.5 4.42831 10.5 3.50005ZM13.94 9.00005C14.5547 9.4758 15.0578 10.0805 15.4137 10.7716C15.7696 11.4626 15.9697 12.2233 16 13.0001V15.0001H20V13.0001C20 13.0001 20 9.37005 13.94 9.00005ZM13 5.2579e-05C12.3117 -0.00378014 11.6385 0.202008 11.07 0.590053C11.6774 1.43879 12.0041 2.45634 12.0041 3.50005C12.0041 4.54377 11.6774 5.56132 11.07 6.41005C11.6385 6.7981 12.3117 7.00389 13 7.00005C13.9283 7.00005 14.8185 6.6313 15.4749 5.97493C16.1313 5.31855 16.5 4.42831 16.5 3.50005C16.5 2.57179 16.1313 1.68156 15.4749 1.02518C14.8185 0.368802 13.9283 5.2579e-05 13 5.2579e-05Z"
                                fill="#32B72F" />
                        </svg>
                    </div>
                    <div>Bis zu 7 Personen</div>
                </div>
                <div class="flex items-center py-2">
                    <div class="mr-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4 12H6L9 15H7L4 12ZM2 2H3V1C3 0.734784 3.10536 0.48043 3.29289 0.292893C3.48043 0.105357 3.73478 0 4 0H8C8.26522 0 8.51957 0.105357 8.70711 0.292893C8.89464 0.48043 9 0.734784 9 1V2H11V1C11 0.734784 11.1054 0.48043 11.2929 0.292893C11.4804 0.105357 11.7348 0 12 0H16C16.2652 0 16.5196 0.105357 16.7071 0.292893C16.8946 0.48043 17 0.734784 17 1V2H18C18.5304 2 19.0391 2.21071 19.4142 2.58579C19.7893 2.96086 20 3.46957 20 4V17C20 17.5304 19.7893 18.0391 19.4142 18.4142C19.0391 18.7893 18.5304 19 18 19V20H15V19H5V20H2V19C1.46957 19 0.960859 18.7893 0.585786 18.4142C0.210714 18.0391 0 17.5304 0 17V4C0 3.46957 0.210714 2.96086 0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2ZM16 5C16.2652 5 16.5196 5.10536 16.7071 5.29289C16.8946 5.48043 17 5.73478 17 6C17 6.26522 16.8946 6.51957 16.7071 6.70711C16.5196 6.89464 16.2652 7 16 7C15.7348 7 15.4804 6.89464 15.2929 6.70711C15.1054 6.51957 15 6.26522 15 6C15 5.73478 15.1054 5.48043 15.2929 5.29289C15.4804 5.10536 15.7348 5 16 5ZM12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6C13 6.26522 12.8946 6.51957 12.7071 6.70711C12.5196 6.89464 12.2652 7 12 7C11.7348 7 11.4804 6.89464 11.2929 6.70711C11.1054 6.51957 11 6.26522 11 6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5ZM18 4H2V8H18V4ZM2 17H18V10H2V17ZM4 5C4.26522 5 4.51957 5.10536 4.70711 5.29289C4.89464 5.48043 5 5.73478 5 6C5 6.26522 4.89464 6.51957 4.70711 6.70711C4.51957 6.89464 4.26522 7 4 7C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5ZM11 12H13L16 15H14L11 12Z"
                                fill="#32B72F" />
                        </svg>
                    </div>
                    <div>Zwei Kachelöfen</div>
                </div>
                <div class="flex items-center py-2">
                    <div class="mr-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.8996 16.5L19.8996 18.1L19.4996 20L10.0996 17.5L0.69961 20L0.19961 18.1L6.19961 16.5L0.0996094 14.9L0.599609 13L9.99961 15.5L19.3996 13L19.8996 14.9L13.8996 16.5ZM14.0696 5.92C13.9096 5.7 13.7096 5.5 13.4996 5.33C13.0496 4.9 12.4996 4.59 12.0596 4.13C10.9996 3.08 10.7896 1.34 11.4396 0C10.7896 0.17 10.1796 0.54 9.66961 0.95C7.83961 2.46 7.10961 5.1 7.99961 7.38C7.99961 7.45 8.03961 7.53 8.03961 7.62C8.03961 7.78 7.92961 7.92 7.78961 8C7.62961 8.05 7.45961 8 7.32961 7.9C7.27961 7.86 7.24961 7.82 7.21961 7.77C6.41961 6.74 6.28961 5.26 6.83961 4.07C5.64961 5.06 4.99961 6.72 5.10961 8.29C5.12961 8.65 5.17961 9 5.29961 9.36C5.40961 9.8 5.58961 10.23 5.81961 10.61C6.56961 11.86 7.89961 12.76 9.31961 12.94C10.8396 13.14 12.4596 12.85 13.6296 11.79C14.9296 10.59 15.3996 8.67 14.7096 7L14.6096 6.83C14.4696 6.5 14.2796 6.2 14.0396 5.93L14.0696 5.92ZM11.8296 10.47C11.6296 10.65 11.3096 10.83 11.0496 10.91C10.2596 11.19 9.45961 10.79 8.99961 10.31C9.83961 10.11 10.3396 9.47 10.4996 8.83C10.6096 8.25 10.3896 7.78 10.2896 7.22C10.2096 6.69 10.2196 6.23 10.4196 5.73C10.5496 6 10.6896 6.28 10.8596 6.5C11.4096 7.22 12.2696 7.54 12.4496 8.5C12.4696 8.63 12.4996 8.72 12.4996 8.83C12.4996 9.43 12.2596 10.07 11.8196 10.47"
                                fill="#32B72F" />
                        </svg>
                    </div>
                    <div>Heizen mit Holz oder Kohle</div>
                </div>
                <div class="mt-6 text-gray-700 tracking-wide text-sm xs:text-base">
                    <ul>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>ausreichend Platz im Wohnraum</div>
                        </li>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>Wintergarten</div>
                        </li>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>Schwalbennest</div>
                        </li>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>Kaminraum</div>
                        </li>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>Fließend warmes Wasser ist vorhanden</div>
                        </li>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>Großer Garten</div>
                        </li>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>Terrasse und Freisitz laden zur Entspannung ein</div>
                        </li>
                        <li class="flex items-center">
                            <div class="mr-2">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="#32B72F"
                                        d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </div>
                            <div>Sauna für 4 Personen im Keller</div>
                        </li>
                    </ul>
                </div>
                <div class="xs:flex justify-end pt-5 xs:pt-0">
                    <a class="hidden xs:block " href="https://www.lauterbach-hessen.de/tourismus/ausflugsplaner/gastgeberverzeichnis.html#!m/3/object/120652">
                        <button class="w-full" mat-outlined-button mat-raised-button style="border: 1px solid brown">Zum Landhaus</button>
                    </a>
                    <a class="xs:hidden" href="https://www.lauterbach-hessen.de/tourismus/ausflugsplaner/gastgeberverzeichnis.html#!m/3/object/120652">
                        <div class="border border-orange-900 rounded-md shadow-two transition-short hover:shadow-five font-poppins font-semibold text-md py-2 text-center">Zum Landhaus</div>
                    </a>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 sm:h-auto sm:grid-cols-4 gap-4 md:gap-10 mt-10 mb-8">
            <figure class="h-40 sm:h-64 shadow-five overflow-hidden">
                <img class="object-cover h-full w-full shadow-two overflow-hidden rounded-md" src="../../assets/images/house1.jpg" alt="">
            </figure>
            <figure class="h-40 sm:h-64 shadow-five overflow-hidden">
                <img class="object-cover h-full w-full shadow-two overflow-hidden rounded-md" src="../../assets/images/house2.jpg" alt="">
            </figure>
            <figure class="h-40 sm:h-64 shadow-five overflow-hidden">
                <img class="object-cover h-full w-full shadow-two overflow-hidden rounded-md" src="../../assets/images/house3.jpg" alt="">
            </figure>
            <figure class="h-40 sm:h-64 shadow-five overflow-hidden">
                <img class="object-cover h-full w-full shadow-two overflow-hidden rounded-md" src="../../assets/images/house4.jpg" alt="">
            </figure>
        </div>
    </section>
</div>
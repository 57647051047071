import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDialogComponent } from 'src/app/components/dialogs/appointment-dialog/appointment-dialog.component';

@Component({
  selector: 'app-betrieblichegesundheit',
  templateUrl: './betrieblichegesundheit.component.html',
  styleUrls: ['./betrieblichegesundheit.component.scss']
})
export class BetrieblichegesundheitComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(AppointmentDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
    });
  }

  ngOnInit(): void {
  }

}

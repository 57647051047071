import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/shared/classes/custom.error-matcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

export class Info {
  show: boolean = false;
  message: string = '';
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  matcher = new CustomErrorStateMatcher();

  error = new Info();
  success = new Info();

  showError(message: string) {
    this.error.show = true;
    this.success.show = false;
    this.error.message = message;
    this.loading = false;
  }
  showSuccess(message: string) {
    this.error.show = false;
    this.success.show = true;
    this.success.message = message;
    this.loading = false;
  }

  loading: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private auth: AuthService
  ) {
    this.registerForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25)
      ]],
      name: ['', [
        Validators.required,
        Validators.pattern('[a-zA-Z].*\s[a-zA-Z].*'),
        Validators.minLength(5),
        Validators.maxLength(40)
      ]],
    })
  }

  ngOnInit(): void {
  }

  get emailRegisterForm() { return this.registerForm.get('email') }
  get passwordRegisterForm() { return this.registerForm.get('password') }
  get nameRegisterForm() { return this.registerForm.get('name') }

  onSubmitRegisterForm(formDirective: FormGroupDirective): void {
    if (this.registerForm.valid) {
      this.loading = true;
      // this.auth.signUp(this.nameRegisterForm.value, this.emailRegisterForm.value, this.passwordRegisterForm.value)
      //   .pipe(take(1))
      //   .subscribe({
      //     next: res => {
      //       // // console.log(`Success`);
      //       if (res.error) {
      //         this.showError(res.error);
      //         formDirective.resetForm();
      //         this.registerForm.reset();
      //       }
      //       else {
      //         this.openSnackBar();
      //         this.showSuccess(res.message);
      //         formDirective.resetForm();
      //         this.registerForm.reset();
      //       }
      //     },
      //     error: err => {
      //       if (err.message === 'internal') this.showError('Es ist ein Fehler aufgetreten. Überprüfen Sie Ihre Verbindung oder versuchen Sie es später erneut.');
      //       else this.showError(err.message)
      //       formDirective.resetForm();
      //       this.registerForm.reset();
      //     },
      //     complete: () => {
      //       // // console.log(`Completeted`)
      //     }
      //   })
    };

  }

  openSnackBar() {
    this._snackBar.openFromComponent(RegisterSnackBarComponent, {
      duration: 10000,
      panelClass: ['bg-green-500']
    });
  }
}

@Component({
  template: `
    <div class="text-center text-white font-poppins font-semibold text-md tracking-wide">
      Erfolgreich registriert!
    </div>
  `
})
export class RegisterSnackBarComponent { }
<div class="relative">
    <div class="fixed top-0 mt-16 right-0 z-40 pt-5 pr-8">
        <button color="primary" mat-raised-button *ngIf="fireService.user$ | async as user"
            (click)="onSave()">Speichern</button>
    </div>
    <section class="px-5 md:px-8 font-poppins font-semibold relative text-center">
        <div class="md:mr-auto md:ml-0 md:max-w-md md:text-left">
            <div class="text-gray-500 font-semibold text-sm sm:mt-2">staatlich geprüfte</div>
            <div class="text-gray-500 font-semibold text-base">Gesundheitsberaterin & Gesundheitspädagogin</div>
        </div>
    </section>
    <section class="xs:px-16 sm:px-5 md:max-w-5xl md:mx-auto">
        <div class="relative z-10">
            <section class="mx-5 shadow-three rounded-lg overflow-hidden mt-10 sm:flex bg-white">
                <div class="hidden sm:block bg-red-700 sm:w-1/3 sm:flex justify-center items-center"
                    style="background: linear-gradient(324.03deg, rgba(255, 255, 255, 0.93) 0%, rgba(255, 255, 255, 0) 100%), rgba(213, 205, 194, 0.93);">
                    <figure class="">
                        <img class="object-cover w-full h-full" src="../assets/images/claudia-full.png"
                            alt="Claudia Fink" title="Claudia Fink">
                    </figure>
                </div>
                <figure class="sm:hidden relative h-48 sm:w-1/3 sm:h-full sm:flex-grow-10 sm:h-full"
                    style="background-color: #cbbfaf;">
                    <img class="object-contain w-full h-full" src="../assets/images/claudia-full.png" alt="Claudia Fink"
                        title="Claudia Fink">
                </figure>
                <div class="font-lato text-gray-900 p-3 sm:w-2/3 sm:flex-grow-10 md:p-6">
                    <div class="font-bold text-lg md:text-xl">Herzlich willkommen,</div>
                    <div class="mt-4 md:mt-6 relative">
                        <button mat-mini-fab [color]="toggleBesuchshundInput ? 'accent': 'primary'"
                                *ngIf="fireService.user$ | async as user" (click)="toggleBesuchshundInput = !toggleBesuchshundInput">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <ng-container *ngIf="toggleBesuchshundInput; else elseTemplateOne">
                            <textarea  class="border flex items-center border-pink-600  w-full" id="besuchshund" name="besuchshund" rows="9"  [(ngModel)]="data$?.about.me" ></textarea>
                        </ng-container>
                        <ng-template #elseTemplateOne>
                            <div style="white-space: pre-wrap" >
                                {{data$?.about.me}}</div>
                        </ng-template>
                        <!-- <div>ich freue mich, dass Sie meine Seite besuchen. Mein Name ist Claudia Fink und ich bin
                            staatlich
                            geprüfte Gesundheitsberaterin & Gesundheitspädagogin. </div>
                        <div class="mt-1">In Balance kommen und einen Weg zu sich selbst finden, ist die Antriebsfeder
                            meiner
                            Arbeit. Der
                            alltag
                            wird immer hektischer und Menschen nehmen sich immer weniger Zeit für ruhige Momente, um zu sich zu finden. Das richtige Maß, ist dabei für jeden Menschen individuell. Ich berate, begleite und
                            unterstütze
                            Sie gern auf dem Weg, Ihre Maßnahmen zu finden und umzusetzen.</div>
                        <div class="mt-1">Mit Vorträgen, Schulungen, individueller Beratung oder eineme Besuch in meiner
                            Praxis
                            werde ich ihnen
                            mit freude zur Seite stehen.</div> -->
                    </div>
                    <div class="sm:flex sm:justify-between sm:items-center sm:mt-2">
                        <div>
                            <div class="mt-3">Ich freue mich auf Sie.</div>
                            <div class="font-marker text-lg md:text-xl">Claudia Fink</div>
                        </div>
                        <!-- <div class="mt-6">
                            <button mat-raised-button class="w-full">Mehr über Claudia erfahren</button>
                        </div> -->
                    </div>
                </div>
            </section>
            <section class="mx-5 shadow-three rounded-lg overflow-hidden mt-16 pb-3 sm:pb-8 bg-white">
                <div class="font-lato font-bold text-lg p-3 pt-5 xs:px-6 md:text-xl">Zertifikate / Diplom</div>
                <div class="md:flex">
                    <figure class="mt-5 mx-3 xs:mx-6">
                        <img class="object-contain w-full h-full" src="../assets/images/cert-1.jpg"
                            alt="Zertifikat PersCert TÜV" title="Zertifikat PersCert TÜV">
                    </figure>
                    <figure class="mt-5 mx-3 xs:mx-6">
                        <img class="object-contain w-full h-full" src="../assets/images/cert-2.jpg" alt="AHAB-Akademie"
                            title="AHAB-Akademie">
                    </figure>
                </div>
            </section>
            <section class="mx-5 shadow-three rounded-lg overflow-hidden mt-16 p-3 sm:p-5 bg-white">
                <div class="font-lato font-bold text-lg mt-2 md:text-xl">Mehr zu meiner Person</div>
                <div class="mt-4">
                    <div class="mt-2">Als Kind war ich zurückhaltend und in Gruppen von Menschen viel lieber zuhörend
                        als aktiv
                        und sichtbar, da ich es schon damals sehr spannend und aufschlussreich fand, was Menschen
                        erzählen und
                        wie sie es tun.
                    </div>
                    <div class="mt-2">Mit der Pubertät entwickelte sich die Fähigkeit Dinge aktiv anzugehen. Gleichzeit
                        lag hier
                        der Zeitpunkt meiner musikalischen Weiterbildung. Eine Lehrerin schlug mir das Geige spielen vor
                        und es dauerte keine 5 Minuten, bis ich wusste: „JA, das
                        möchte ich lernen“. Bis heute liebe ich die wohltuenden Schwingungen, die jede Art von Tönen
                        erzeugt und so ist es kein
                        Wunder, dass die Klangschalen eines meiner Therapiemittel geworden sind.</div>
                    <div class="mt-2">Beruflich schwankte ich nach dem Abitur zwischen Logopädie und klassischer
                        Medizin. Man
                        riet mir erst mal zu einer medizinischen Grundausbildung und so absolvierte ich meine Ausbildung
                        zur
                        Arzthelferin bei unserem Hausarzt.
                    </div>
                    <div class="mt-2">
                        Es war eine weitere glückliche Entscheidung. Sie war der erste starke Impuls für meine
                        berufliche
                        Bildung. Ruhe, Geduld und eine menschlich zugewandte Behandlungsweise prägten die Arbeit in
                        dieser Zeit.
                        Auch mein weiterer beruflicher Weg war bestimmt von Kollegen, die Menschen mit Achtung,
                        Aufmerksamkeit
                        und liebevoller Fürsorge begegneten.
                    </div>
                    <div class="mt-2">
                        Die Entscheidung nach meiner Weiterbildung zur Arbeitsmedizinischen Assistentin noch ein Studium
                        der
                        Gesundheitspädagogik zu absolvieren, war eine Fortsetzung dieses Weges und der Erkenntnis, dass
                        es
                        Menschen im ersten Schritt hilft, Ihnen zu erklären, woran sie erkrankt sind, im zweiten Schritt
                        aber
                        Unterstützung bei der Gesundheitsförderung und -erhaltung ebenso wichtig ist.
                    </div>
                </div>
            </section>
            <section class="mt-10 p-5">
                <a routerLink="/home">
                    <button mat-raised-button class="w-full">Zum Startbildschirm</button></a>
            </section>
        </div>
    </section>
    <section class="fixed z-0 inset-0 w-screen h-screen">
        <div class="bg-white opacity-50 absolute inset-0"></div>
        <figure class="h-full w-full">
            <img class="object-cover h-full w-full" src="../assets/images/mountains.jpg" alt="Curvy Road">
        </figure>
    </section>

</div>
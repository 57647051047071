<div class="bg-white md:p-10 p-5 text-center rounded-md overflow-hidden my-8">
    <div class="font-poppins text-xl leading-6  sm:text-2xl text-gray-800 font-semibold">{{offer.name}}</div>
    <div class="font-poppins text-md sm:text-lg text-gray-500 font-medium mt-3">{{offer.info}}</div>
    <div class="max-w-xl mx-auto">
        <figure class="mt-10 w-full h-full">
            <img class="w-full h-56 object-cover shadow-two rounded-md overflow-hidden" [src]="offer.url" alt="">
        </figure>
        <div class="text-gray-800 font-medium tracking-wide leading-5 mt-4 text-left xs:font-lato font-nunito text-center text-base sm:text-left">{{offer.description}}</div>
    </div>
    <div class="mt-5 sm:mt-12 xs:mx-8 sm:mx-16">
        <div class=" text-lg text-gray-600 font-semibold font-nunito">
            {{offer.places.length > 1 ? offer.places.length: ''}}
            {{offer.places.length > 1 ? 'Standorte': 'Standort'}}
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 text-left font-nunito mt-5 sm:mt-12 gap-5">
            <div *ngFor="let place of offer.places; let i = index" [ngClass]="{'sm:mr-auto': i === 0, 'sm:ml-auto': i === 1}" class="text-center sm:text-left  sm:mt-0 mt-5">
                <div class="xs:flex md:flex-row flex-col">
                    <div class="text-md font-semibold text-gray-600 sm:w-12 md:pb-0 pb-2">Ort</div>
                    <div>
                        <div class="font-medium text-base sm:text-md text-gray-800">{{place.location}}</div>
                        <div class="font-medium text-base sm:text-md text-gray-800">{{place.address}}</div>
                    </div>
                </div>
                <div class="flex mt-5 md:flex-row flex-col">
                    <div class="text-md font-semibold text-gray-600 sm:w-12 md:pb-0 pb-2">Zeit</div>
                    <div>
                        <div *ngFor="let time of place.times; let i=index">
                            <div [ngClass]="{'mt-2': i > 0}" class="font-medium text-md text-green-500">
                                {{time.day}}
                            </div>
                            <div  class="font-medium text-md text-gray-800" *ngFor="let hour of time.hours">
                                {{hour.h}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
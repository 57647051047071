import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/service/firebase.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-besuchshund',
  templateUrl: './besuchshund.component.html',
  styleUrls: ['./besuchshund.component.scss']
})
export class BesuchshundComponent implements OnInit {

  data$: any;
  
  constructor(
    public dialog: MatDialog,
    public fireService: FirebaseService,
  ) { } 
    
    ngOnInit(): void {
      this.fireService.loadOtherData();
      this.fireService.other$.subscribe(data => {
        this.data$ = data;
      })
    }
    
    toggleBesuchshundInput: boolean = false;

    async onSave() {
      this.fireService.updateOtherData(this.data$);
    }



}

import { Component, OnInit } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDialogComponent } from './components/dialogs/appointment-dialog/appointment-dialog.component';
import { AuthService } from './shared/services/auth.service';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { FirebaseService } from './service/firebase.service';
// import * as firebase from 'firebase';
// import { environment } from 'src/environments/environment';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  subscription: Subscription;

  selectedValue: string;
  selectedLang: string = 'de';
  mode = new FormControl('over');
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];

  constructor(
    public router: Router,
    // public translate: TranslateService,
    private dialog: MatDialog,
    public fireService: FirebaseService,
    readonly viewportScroller: ViewportScroller
  ) {


    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: NavigationEnd) => {
        // Angular v2-v6
        window.scroll(0, 0);
        // Angular v7+
        this.viewportScroller.scrollToPosition([0, 0]);
      });

  }

  ngOnInit() {
    // this.subscription = this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // )
    //   .subscribe(() => window.scrollTo(0, 0))
  }

  // navigateTo(value){
  //   // // console.log(value);
  //   this.router.navigate(['/',value]);

  // }

  openDialog(): void {
    const dialogRef = this.dialog.open(AppointmentDialogComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // // console.log('The dialog was closed');
    });
  }

  onActivate(event) {
    // // console.log(`scroll triggered`);
    window.scroll(0, 0);
  }
}
